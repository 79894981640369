.walletHistoryCard{
    display: flex;
    justify-content: space-between;
    padding: 1%;
    align-items: center;
}
.fundAdd{
    display: flex;
    width: 200px;
    align-items: center;
}
.fundAddDate{
    margin-left: 9%
}
.fundAddDate h6{
    margin-bottom: 0px
}
.fundAddDate p{
    margin-bottom: 0px !important;
    font-size: 8px !important;
}
.historyCard{
    margin-top: 4%;
    padding: 2%;
    background-color: #fff;
}

.wallet-password-wrap{
    background-color: #fff;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    
}

.wallet-pass-Err{
    color: #cf0000;
    font-size: 12px;

}

.w-p-c{
    border: 1px red solid;
    padding: 1rem 2rem;

}

.wallet-wrap{
    min-height: 75vh;
    background-color: #fff;
}

/*wallet  style*/
.wallet-box{
    margin-top: 30px;
    text-align: center;
    min-height: 420px; 
  }
  
  .balance-header{
    font-weight: 400;
    font-size: 14px;
    color: #353535;
    margin-top: 20px;
  }
  
  .balance{
    font-size: 40px;
  }
  
  .wallet-action {
    margin-top: 30px;
    overflow: hidden;
  }
  
  .wallet-action button{
    padding: 5px 10px;
    border-radius: 7px;
    width: 120px; 
    border:1px solid #E2231A;
    background: #fff;
    color: #E2231A;
    font-weight: 80;
    font-size: 16px;
    margin: 0 auto;
  }
  
  .wallet-action button:hover{
    background: #E2231A;
    color: #fff;
  
  }

  .wallet-body{
    margin: 5% auto;
    text-align: center;
    padding: 2rem 0;
  }

 

  .wallet-logo{
    height: 100px;
    width:100px;
    margin: auto;
  }
  
  .wallet-logo img{
    height: 100%;
    width: 100%;
    border-radius: 50%;
  }

  .topup-box{
    min-height: 420px;
    margin-top: 30px;
    padding: 10px;
  }
  
  .topup-title{
    font-weight: 300;
    font-size: 16px;
  }
  
  .topup-amt{
    overflow: hidden;
    margin-top: 5%;
    margin-bottom:10px !important;
    padding: 0 !important;
  }
  
  .topup-amt div{
    margin-right: 7px;
    padding: 10px;
    border-radius: 3px;
    border:1px solid #c5c5c5;
    cursor: pointer;
  }
  
  .topup-amt div:last-child{
    margin-right: 0;
    padding: 0;
  }
  
  #topup-input input{
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border:0;
    border:1px solid #E2231A;
  }

  #topup-input input:focus{
    outline: none;
  }

  #topup-input {
    width: 100%;
    margin: 0 auto;
  }
  
  .ft-amt span{
    font-weight: 800;
    color:#1AB768;
    font-size: 18px;
  }

  .ft-amt {
    font-weight: 800;
    color:#1AB768;
    font-size: 18px;
  }
  
  
  .topup-amt div:not(:last-child):hover{
    background: #1AB768;
    color: #ffffff;
  }

  .wm{
    margin-bottom: 5px !important;
    padding: 8px;
    background-color: #F2F2F2;
    clear: both;
    }
      
      .with-method{
        box-sizing: border-box;
        cursor: pointer;
        position: relative;

      }

      .with-method img{
        width: 100%;
        height: 50px;
     }

     .with-method div{
        height: 30px;
        color: #fff;
        background-color: #E2231A;
        line-height: 30px;
        font-weight: bolder;
     }
      
      .method-checked{
        box-sizing: border-box;
        box-shadow: 0 0 0 2px #1AB768;
      }
      

   
      
     
      .trans-btn{
        display: block;
        width: 100%;
        border: none;
        background-color: #F2F2F2;
        padding: 10px 28px;
        font-size: 16px;
        font-weight: 100;
        letter-spacing: 2px;
        cursor: pointer;
        text-align: center;
        color: #C5C4C4;
        border-radius: 5px;
      }
      
      /*----------withdraw page style---------*/
      
      .trans-btn-active{
        background: crimson; 
        color:#FFFFFF !important;
        cursor: pointer;
      }
      
      .trans-btn:disabled{
        cursor: not-allowed; 
      }


      .wm-hover::before,
      .wm-hover::after{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        border-color: transparent;
        border-style: solid;
      }
      
      .wm-hover::after {
        content: '\2713';
        font-size: 13px;
        line-height: 13px;
        font-weight: bold;
        color: white;
      }
      
      .wm-hover::before {
        border-radius: 0;
        border-width: 12px;
        border-left-color: #ccc;
        border-top-color: #ccc;
      }

      /*selected payment method*/

      .wm-selected::before,
      .wm-selected::after{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        border-color: transparent;
        border-style: solid;
      }
      
      .wm-selected::after {
        content: '\2713';
        font-size: 13px;
        line-height: 13px;
        font-weight: bold;
        color: white;
      }
      
      .wm-selected::before {
        border-radius: 0;
        border-width: 12px;
        border-left-color: #E2231A;
        border-top-color: #E2231A;
      }


       /*--------------------------------------*/
    .payment-info{
        border-radius: 5px;
        padding: 15px;
        background-color: #ffffff;
        flex-basis: 45%;
        margin-bottom: 2rem;
    }

    @media(max-width:767px) {
        .payment-info{
            flex-basis: 80%;
        }
    }

    .payment-info-header{
        border-bottom:1px solid #cccccc;
        text-align: center;
        padding: 12px;
    }

    .payment-info-header img{
        width: 70px;
        height: 70px;
        border:1px solid #cccccc;
        border-radius: 4px;
    }
     .payment-info-header span{
        font-size: 25px;
        font-weight: 450;
        margin-left: 30px;
    }

    .code-container{
        border-bottom: 1px solid #cccccc;
        text-align: center; 
    }

    .bank_info table tr td:nth-child(1){
      text-align: left !important;
    }
    .bank_info table tr td:nth-child(2){
      text-align: right !important;
    }
    .bank_info table tr td:nth-child(3){
      text-align: left !important;
      width: 5%;
      cursor: pointer;
    }

    .code-container img{
        width: 50%;
        height:50%;  
    }

    .instriction-box{
        padding-top:20px;
    }

    .instriction-box li:nth-child(4){
        color:#E2231A;
    }

    .dep-amt{
        font-size: 40px;
        font-weight: 400;
        margin-top: 10px;
        margin-bottom: -5px;
    }

    .dep-amt span{
        font-size: 40px;
        font-weight: 400;
    }

    .complete-transfer button{
      width: 20%;
      border: none;
      background-color: #E2231A;
      padding: 8px;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
      color: #FFFFFF;
      border-radius: 5px;
    }

     .complete-transfer{
      text-align: center;
    }

.payment-complted{
    width: 340px;
    margin: 10% auto;
    text-align: center; 
}



 .payment-complted button{
      border: none;
      background-color: #E2231A;
      padding: 8px;
      font-size: 16px;
      font-weight: 400;
      cursor: pointer;
      color: #FFFFFF;
  }


     .copyNotice{
        position: absolute;
        background: rgba(0,0,0, 0.5);
        color:#fff;
        font-size:12px;
        text-align: center;
        border-radius:4px;
        top:-1.5rem;
        right:20px;
        padding:2px 6px;
        display:none;
    }

  