.formWrapper {
  /* border: 1px red solid; */
  width: 100%;
  max-width: 40rem;
  box-shadow: 0 0.3em 0.5em rgba(0, 0, 0, 0.3);
  border-radius: 0.3rem;
  padding: 1rem;
  margin: 0 auto;
}
.FormTitle {
  margin-bottom: 1.5rem;
  font-size: 2rem;
}
.formContainer {
  /* border: 1px red solid; */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
}
.nameBox {
  /* border: 1px red solid; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.textInput,
.nameTextInput {
  border: 1px gray solid;
  border-radius: 0.2rem;
  outline: none;
  width: 100%;
  padding: 0.6rem;
  font-size: 1.2rem;
}
.nameTextInput {
  max-width: 48%;
}
.textInput::placeholder,
.nameTextInput::placeholder {
  font-size: 1.2rem;
  color: lightgrey;
}
.messageArea {
  border: 1px gray solid;
  border-radius: 0.2rem;
  outline: none;
  width: 100%;
  min-height: 10rem;
  padding: 0.6rem;
  font-size: 1.2rem;
  resize: none;
}
.messageArea::placeholder {
  font-size: 1.2rem;
  color: lightgrey;
}
.submitBtn {
  border: none;
  outline: none;
  background-color: #cf0000;
  width: 100%;
  padding: 0.6rem 0;
  color: #fff;
  font-size: 1rem;
  margin-top: 0.5rem;
  border-radius: 0.3rem;
}
