.wrapper{
  /* border: 1px red solid; */
  width: 100%;
  /* height: 100%; */
}
.container{
  /* border: 1px red solid; */
  width: 100%;
  margin-bottom: 2rem;
}
.phoneUpdateWrapper{
  /* border: 1px red solid; */
}
.phoneNumberBox{
  /* border: 1px red solid; */
  width: 100%;
  margin-bottom: 1rem;
}
.phoneUpdateBox{
  width: 100%;
  color: red;
}
.regErr{
  color: red;
}
.codeContainer{
   /* border: 1px red solid; */
  width: 100%;
}
.codeBox{
  /* border: 1px red solid; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.getCode{
  cursor: pointer;
}
.codeInput{
  /* border: 1px red solid; */
  width: 100%;
  max-width: 7rem;
}
.codeInput input{
  outline: none;
  border: 1px grey solid;
  width: 100%;
}
.verifiedBox{
  border: 1px red solid;
  margin-top: 1rem;
}
.btn{
  display: block;
  width: 100%;
  max-width: 10rem;
  margin: 0 auto;
  outline: none;
  border: none;
  padding: 0.5rem 0;
  color: #fff;
  border-radius: 0.3rem;
  background-color: #cf0000;
  font-size: 1.1rem;
}