.description-tab-wrapper{
    /* border: 1px red solid; */
    width: 100%;
}
.payment-delivery{
    padding:4rem;
    text-align:justify;
    background-color:#f3f3f3;
     color:#333;
}

.desc_img img{
    height: 60%;
    width: 60%;
    margin: auto!important;
}

.item-attr-wrap{
    margin-top:20px;
    display: flex;
}

.item-attr-name{
    color: #999999;
}

.item-attr-wrap > div{
    font-size: 14px;
    flex-basis: 50%;
    padding:0.5em 0;
}

.item-attr-value {
    max-height: 6rem;
    overflow: hidden;
}