.footer-widget{
    background-color: #1a1a1a;
}

.widget-title {
    font-size: calc(0.8rem + 0.3vw) !important;
}
.footer-widget-area{
    background-color: #1a1a1a;
    /* margin-top:10rem; */
}
.widget-content ul li Link {
    color: white !important;
    text-decoration: none
}
.footcon{
    display: flex;
    justify-content: space-around;
}
.text-white{
    text-decoration: none !important;
}
.footer-copyright{
    background-color: #cf0000;
}