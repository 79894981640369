.wrapper{
  /* border: 1px red solid; */
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.container{
  /* border: 1px red solid; */
  width: 100%;
  max-width: 75%;
  min-height: 80vh;
  margin: 0 auto;
  padding: 2rem 0;
  background-color: #fff;
  border-radius: 0.3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5rem;
}
.title{
  /* border: 1px red solid; */
  width: 100%;
  max-width: max-content;
  margin: 0 auto;
  margin-bottom: 1.5rem;
}
.tableWrapper{
  /* border: 1px red solid; */
  width: 100%;
  max-width: 55rem;
  margin: 0 auto;
  box-shadow: 0 0.3em 0.5em rgba(0, 0, 0, 0.3);
  border-radius: 0.3rem;
  margin-bottom: 1.5rem;
}
.tableWrapper thead, .tableWrapper tr{
  width: 100%;
  /* border: 1px red solid; */
}
.tableWrapper thead th, .tableWrapper tbody td{
  /* border: 1px red solid; */
  width: 20%;
  text-transform: capitalize;
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
  padding: 1rem 0.5rem;
}
.tableWrapper thead .largerWidth{
  width: 35%;
}
.tableWrapper thead .time{
  width: 30%;
}
.tableWrapper tbody{
  width: 100%;
}
.tableWrapper tbody tr{
  width: 100%;
}
.tableWrapper tr:hover{
  background-color: #ddd;
}
.tableWrapper tbody td{
  font-weight: 200;
}
.tableWrapper tbody .subject{
  width: 100%;
  height: 3.1rem;
  text-transform: lowercase;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.tableWrapper tbody .subject::first-letter{
  text-transform: uppercase;
}
.tableWrapper tr{
  border-bottom: 1px gray solid;
}
.tableWrapper tbody td .view{
  /* border: 1px red solid; */
  width: 100%;
  max-width: max-content;
  padding: 0.3rem;
  box-shadow: 0 0.3em 0.5em rgba(0, 0, 0, 0.3);
  font-size: 1.2rem;
  cursor: pointer;
}
.tableWrapper tbody:nth-child(odd){
  background-color: #f2f2f2;
}
.unread{
  color: red;
}
.read{
  color: green;
}
.pageBox{
  /* border: 1px red solid; */
  width: 100%;
  max-width: 55rem;
  margin: 0 auto;
}
.ModalTitle{
  border-top: 2px lightgrey solid;
  border-bottom: 2px lightgrey solid;
  width: 100%;
  padding: 1rem 1.5rem;
  margin-bottom: 1rem;
}
.ModalTitle::first-letter{
  text-transform: uppercase;
}
.infoBox{
  /* border: 1px red solid; */
  width: 100%;
  padding: 1rem 1.5rem;
}
.modalInfo{
  /* border: 1px red solid; */
  width: 100%;
  padding: 0;
  margin: 0;
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
}
.modalInfo::first-letter{
  text-transform: uppercase;
}
.info2{
  width: 100%;
  font-size: 1.1rem;
  font-weight: 600;
}
.info2::first-letter{
  text-transform: uppercase;
}
.modalDate{
  border-top: 2px lightgrey solid;
  border-bottom: 2px lightgrey solid;
  padding: 1rem 1.5rem;
  font-size: 1.1rem;
  font-style: italic;
}