.layout-container{
    min-height: 75vh;
    margin: 1rem 10%;
}


  .layout-container .closemenu {
    color: #000;
    position: absolute;
    right: 0;
    z-index: 9999;
    line-height: 15px;
    border-radius: 50%;
    font-weight: bolder;
    font-size: 22px;
    top: 45px;
    cursor: pointer;
  }
 
  .layout-container  .logotext p {
    font-size: 20px;
    padding: 10px 20px 0;
    color: #000;
    font-weight: bold;
    
  }

  .layout-container .sidebar .sidebar-inner .smalllogotext{
    /* border: 1px red solid; */
    width: 100%;
    height: 2rem;
    margin: 1.5rem auto;
    /* border-bottom: 1px solid #ccc !important; */
  }

  .layout-container .sidebar .sidebar-inner .biglogotext {
    /* border: 1px red solid; */
    width: 100%;
    max-width: 70%;
    height: 3rem;
    margin: 1.5rem auto;
    /* border-bottom: 1px solid #ccc !important; */
    /* margin-bottom: 2rem;; */
  }
  .biglogotext img, .smalllogotext img{
    width: 100%;
    height: 100%;
  }

  .menu .active .menu-anchor{
    background-color: crimson !important;
    color: #fff;
  }
  
  .menu  .menu-anchor .menu-icon{
    background-color: crimson !important;
    color: #fff;
  }
  .layout-container main{
    flex-basis: 100%;
    
    padding: 0 0.5rem;
  }
  