.wrapper{
  /* border: 1px red solid; */
  width: 100%;
  max-width: max-content;
  position: relative;
}
.accountBox {
  /* border: 1px white solid; */
  width: 100%;
  max-width: max-content;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.accountIcon {
  font-size: 1.5rem;
}
.accountBox span {
  font-size: 0.85rem;
  margin: 0 0.4rem;
  text-transform: capitalize;
}
.accountBox:hover span{
  color: #EDEDED;
}
.options {
  width: 10rem;
  min-height: 5rem;
  padding: 1rem 0;
  position: absolute;
  top: 2.5rem;
  left: -2.5rem;
  background-color: #ededed;
  border-radius: 0.3rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0.5rem;
  z-index: 20;
  display: none;
}
.openBox{
  display: flex;
}
.option {
  width: 100%;
  text-decoration: none;
  color: black;
  font-style: italic;
  padding: 0.2rem 0;
  padding-left: 0.8rem;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 0.85rem;
}
.option:hover{
  background-color: #fff;
  color: #CF0000;
}