
.create-order-content{
    min-height: 73vh;
    padding:3rem 15%;
    
}

.create-order-content > .address-wrap{
    background-color: #ffffff;
    padding: 2rem;
    margin-bottom: 1.5rem;
}

.order-info-wrap > .store-name{
    background-color: #fff6f6;
    padding: 1rem;
}

.order-info-wrap:not(:last-child){
    margin-bottom: 2rem;
}

.order-info-wrap > .order-body{
    padding: 2rem;
}
.create-order-content > .order-info-wrap{
    background-color: #ffffff;
}

.order-body > .order-wrap{
    flex-basis: 90%;
}

.order-list-container > .right{
    flex-basis: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bolder;
    border-left: 1px solid #ffcfcf;
}

.order-list-container > .left {
    flex-basis: 88%;
}

.order-list-container > .left > .inner{
    flex-basis: 100%;
}

.order-list-container > .left > .inner > .inner-child{
    flex-basis: 100%;
} 

.order-wrap{
    background-color:#fdf8f8;
    border: 1px solid #ffcfcf;
}

.order-wrap > .column-title{
    padding: 1rem;
    border-bottom: 1px solid #ffcfcf;
}


.order-wrap > .comment-and-total{
    padding: 1rem;
}

.order-wrap > .comment-and-total{
    padding: 1rem;
    border-top: 1px solid #ffcfcf;
}

.inner > .inner-child  > .sku-info{
    flex-basis: 40%;
    margin: 1rem 0;
}

.inner > .inner-child > .sku-listed-price{
    flex-basis: 12%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
}
.inner > .inner-child > .sku-qty{
    flex-basis: 12%;
    display: flex;
    justify-content: center;
    align-items: center; 
    margin: 1rem 0;
}
.inner > .inner-child > .discount{
    flex-basis: 12%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
}
.inner > .inner-child > .price{
    flex-basis: 12%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem 0;
}

.inner > .inner-child > .sku-info > img{
    height: 70px;
    width: 70px;
    flex-basis: 10%;
}

.inner > .inner-child > .sku-info > .item-info{
    flex-basis: 80%;
}

.order-item-title{
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.item-info > .spec-info{
    font-weight: bold;
}

.inner:not(:last-child){
    border-bottom: 1px solid #ffcfcf
}

.inner-child{
    margin-left: 1rem;;
}

.order-text-area{
    border: none;
    border-radius: 4px;
    padding-left: 0.5rem;
    padding-top: 0.3rem;
    font-size: 12px;
}

.order-error-wrap{
    min-height: 40vh;
    background-color: #ffffff;
    padding-left: 25%;
    padding-top: 5%;
    padding-bottom: 5%;
}

.order-error-wrap img{
    height: 20rem;
    width: 30rem;
}


.order-error-wrap div{
    color:#a6a6a6;
    padding-left: 3rem;
}

.questionMark{
    color: #ffffff;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    text-align: center;
    cursor: pointer;
}

.order-comment > textarea{
    outline: none;
}

.checkout-btn-wrap{
    margin-top: 1.5rem;
    justify-content: center;
    align-items: center;
}


.checkout-btn-wrap .checkout-btn button{
    border: 0;
    color: #ffffff;
    padding: 1rem 2rem;
    font-weight: bolder;
    font-size: 16px;
    background-color: crimson;
    margin-left: 2rem;
    
}

.checkout-btn-wrap .checkout-btn button:hover{
    background-color: #ed3d61;
}

.checkout-btn-wrap .checkout-btn button img{
    height: 1rem;
    width: 1rem;
    margin-right: 0.3rem;
}

.checkout-btn-wrap div .amt{
    color: crimson;
    font-weight: bolder;
    font-size: 18px;
}

.checkout-btn-wrap div .currency{
    color: crimson;
    font-weight: bold;
    font-size: 14px;
    margin-right: 0.5rem;;
}

.checkout-info-wrap{
    padding: 2rem;
}

.payable-amt{
    margin: 1.2rem 0;
}

.payable-amt div{
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    flex-basis: 50%;
    padding: 0.5rem 0;
    justify-content: center;
    align-items: center;
}


.payable-amt div .amt{
    color: crimson;
    font-weight: bolder;
    font-size: 18px;
}

.payable-amt div .currency{
    color: crimson;
    font-weight: bold;
    font-size: 14px;
    margin-right: 0.5rem;;
}

.total-item-price div .amt{
    color: crimson;
    font-weight: bold;
    font-size: 14px;
}


