
.payment-wrap{
  min-height: 73vh;
  margin:0 15%;
  padding: 1.5rem;
}
.how-to-pay ol li a{
  /* border: 1px red solid; */
  width: 100%;
  max-width: max-content;
}

.recommended-label{
  height: 2.5rem;
  background-color: #fff6f6;
  text-align: center;
  line-height: 2.5rem;
  margin-bottom: 1rem;
}

.order-info-wrap{
  border-top: 1px solid red;
  padding-top: 0.5rem;
}

.order-info-wrap .order-info{
  flex-basis: 55%;
  padding-top: 1rem;
}

.order-info-wrap .order-info .order-title{
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.order-info-wrap .order-img{
  flex-basis: 10%;
}

.order-info-wrap .order-amt{
  flex-basis: 30%;
}

.order-info-wrap .order-img img{
  height: 6rem;
  width: 6rem;
  border-radius: 4px;
  border: 1px solid #f2f2f2;
}

.pm-wrap{
  height: 7rem;
  margin-bottom: 1.5rem;
  border-radius: 5px;
  border: 1px solid #ccc;
  align-items: center;
  position: relative;
  cursor: pointer;
}


.payment-method-logo{
  background-color: #fff;
  height: 5rem;
  text-align: center;
  flex-basis: 15%;
}
.payment-method-logo img{
  width: 5rem;
  height: 5rem;
  border: 1px solid #ccc;
  border-radius: 50%;
}

.payment-method-title{
  color: #999;
  height: 4.5rem;
  line-height: 4.5rem;
  flex-basis: 40%;
  font-size: 18px;
}



.order-price .currency{
  color: crimson;
  font-size: 18px;
}

.order-price .total{
  color: crimson;
  font-weight: bolder;
  font-size: 24px;
}

.order-limit-time{
  color: crimson;
  font-weight: bold;
  font-size: 18px;
}

.order-id span{
  font-size: bolder;
  font-size: 16px;
}


.pm-hover {
  border: solid 1px #ccc;
}

.pm-hover::before,
.pm-hover::after{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  border-color: transparent;
  border-style: solid;
}

.pm-hover::after {
  content: '\2713';
  font-size: 13px;
  line-height: 13px;
  font-weight: bold;
  color: white;
}

.pm-hover::before {
  border-radius: 0;
  border-width: 12px;
  border-left-color: #ccc;
  border-top-color: #ccc;
}



.pm-selected {
  border: solid 1px crimson;
}

.pm-selected::before,
.pm-selected::after{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  border-color: transparent;
  border-style: solid;
}

.pm-selected::after {
  content: '\2713';
  font-size: 13px;
  line-height: 13px;
  font-weight: bold;
  color: white;
}

.pm-selected::before {
  border-radius: 0;
  border-width: 12px;
  border-left-color: crimson;
  border-top-color: crimson;
}

.pay-now-wrap div{
  flex-basis: 20%;
}

.pay-now-wrap div button{
  padding: 1rem 2rem;
  background-color: crimson;
  color: #fff;
  font-weight: bolder;
  font-size: 18px;
  border: none;
}

.pay-now-wrap{
  margin: 2rem 15%;
}

.pm-alert{
  margin: 0 15%;
  padding: 2rem;
  background-color: #fff;
  min-height: 75vh;
}
