.wrapper{
  /* border: 1px red solid; */
  width: 100%;
  min-height: 100vh;
}
.container{
  /* border: 1px red solid; */
  width: 100%;
  max-width: 75%;
  height: 100%;
  margin: 2rem auto;
  padding: 1rem 6rem;
  border-radius: 0.3rem;
  background: linear-gradient(to top, #fff, #ddd);
}
.title{
  /* border: 1px red solid; */
  width: 100%;
  text-align: center;
  margin-bottom: 2rem;
  text-transform: capitalize;
}
.policyBox{
  /* border: 1px red solid; */
  width: 100%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
}
.update{
  /* border: 1px red solid; */
  width: 100%;
  font-size: 1.1rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.policyInfo{
  /* border: 1px red solid; */
  width: 100%;
  max-width: 50%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.para{
  /* border: 1px red solid; */
  font-size: 1.2rem;
}
.cookiesDefinition{
  /* border: 1px red solid; */
  width: 100%;
}
.cookiesDefinition h4{
  font-weight: 600;
}
.cookiesDefinition span{
  font-size: 1.2rem;
}