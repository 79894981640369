.wrapper {
  /* border: 1px red solid; */
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}
.container {
  /* border: 1px red solid; */
  width: 100%;
  max-width: 75%;
  min-height: 40rem;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 0.3rem;
  padding: 2rem;
}
.pageTitle {
  /* border: 1px red solid; */
  width: 100%;
  max-width: max-content;
  margin: 0 auto;
  font-weight: 600;
  margin-bottom: 2rem;
}
.amountNoticeBox {
  /* border: 1px red solid; */
  width: 100%;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.amountNoticeBox .noticeInfo {
  font-size: 1.1rem;
  font-weight: 600;
  color: #333333;
  margin-bottom: 0;
}
.amountNoticeBox .noticeInfo b {
  font-size: 1.1rem;
  color: #cf0000;
}
.amountNotice {
  /* border: 1px red solid; */
  width: 100%;
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: column;
  gap: 0.5rem;
  /* align-items: center;
  justify-content: space-between; */
}
.amountNoticeBox .amountNotice .notice {
  font-size: 0.9rem;
  color: #333333;
  /* border: 1px red solid; */
  width: 100%;
  max-width: max-content;
  padding: 0.5rem 0.8rem;
  border-radius: 1.5rem;
  background-color: #ededed;
  font-weight: 600;
  flex: 0 0 auto;
}
.amountNoticeBox .amountNotice .notice b {
  font-size: 0.9rem;
  color: #333333;
}
.boxesContainer {
  /* border: 1px red solid; */
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 3rem;
}
.box1 {
  /* border: 1px red solid; */
  width: 100%;
  max-width: 55%;
}
.title {
  /* border: 1px red solid; */
  width: 100%;
  max-width: max-content;
  font-weight: 600;
  margin-bottom: 1.5rem;
}
.result {
  /* border: 1px red solid; */
  width: 100%;
  max-width: max-content;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1rem;
}
.result span,
.result span b {
  font-size: 1.2rem;
}
.error {
  width: 100%;
  font-size: 1.2rem;
  text-align: center;
  color: #cf0000;
}
.result b {
  font-size: 1.5rem;
  color: #cf0000;
}
.calculatorBox {
  /* border: 1px red solid; */
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 5%;
  margin-bottom: 2rem;
}
.inputBox {
  /* border: 1px red solid; */
  width: 100%;
  max-width: 45%;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  margin-bottom: 5%;
}
.inputBox:last-child {
  margin-bottom: 0;
}
.inputLabel {
  font-size: 1rem;
  color: #cf0000;
  font-weight: 600;
  text-transform: capitalize;
}
.inputdetail {
  border: 1px grey solid;
  outline: none;
  width: 100%;
  padding: 0.3rem;
  border-radius: 0.3rem;
  font-size: 1.1rem;
}
.otherparamsBox {
  /* border: 1px red solid; */
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
}
.otherparams {
  /* border: 1px red solid; */
  width: 100%;
  font-size: 1.1rem;
  font-weight: 600;
  color: gray;
}
.otherparams span {
  color: #cf0000;
  font-size: 1.1rem;
  font-weight: 600;
}
.otherparamsInputBox {
  /* border: 1px red solid; */
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  /* justify-content: space-between; */
  gap: 1.5rem;
}
.otherparamsInput {
  /* border: 1px red solid; */
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.otherparamsInput label {
  font-size: 1.1rem;
}
.otherparamsInput input {
  border: none;
  outline: none;
  padding: 0.3rem;
  background-color: #ededed;
  border-radius: 0.5rem;
}
.otherparamsInput input::placeholder {
  font-size: 1.1rem;
  color: gray;
}
.btnHolder {
  /* border: 1px red solid; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn {
  display: block;
  outline: none;
  border: none;
  width: 100%;
  max-width: 12rem;
  padding: 0.5rem;
  background-color: #cf0000;
  color: #fff;
  font-size: 1.2rem;
  border-radius: 0.5rem;
  margin: 0 auto;
}
.btnClear {
  border: 1px #cf0000 solid;
  display: block;
  outline: none;
  width: 100%;
  max-width: 10rem;
  padding: 0.5rem;
  color: #cf0000;
  background-color: #fff;
  font-size: 1.2rem;
  border-radius: 0.5rem;
  margin: 0 auto;
}
.hideClearBtn {
  display: none;
}
.box2 {
  /* border: 1px red solid; */
  width: 100%;
  max-width: 50%;
}
.box2ContentBox {
  /* border: 1px red solid; */
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 2rem;
}
.box2Content {
  /* border: 1px red solid; */
  width: 100%;
  max-width: 10.7rem;
  padding: 0.3rem;
  box-shadow: 0 0.3em 0.5em rgba(0, 0, 0, 0.3);
  border-radius: 0.3rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.iconBox {
  /* border: 1px red solid; */
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10rem;
  background-color: #ededed;
  cursor: pointer;
}
.box2Content span {
  /* border: 1px red solid; */
  font-size: 1.1rem;
  font-weight: 600;
  color: grey;
}
.note {
  margin-bottom: 0;
  width: 100%;
  color: #cf0000;
  font-size: 1.1rem;
  font-weight: 600;
  text-align: center;
}
