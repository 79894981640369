.urlinput{
    margin-left: auto;
    margin-right: auto;
}

.field {
    position:realtive;
    margin:2em auto;
    /* width:500px; */
    flex-direction:row;
    /* -moz-box-shadow:    0px 0px 2px 0px rgba(0,0,0,0.2); */
    /* -webkit-box-shadow: 0px 0px 2px 0px rgba(0,0,0,0.2); */
    /* box-shadow:         0px 0px 2px 0px rgba(0,0,0,0.2); */
  }
  
  .field>input[type=text],
  .field>button {
    display:block;
  }
  
  .field>input[type=text] {
    flex-basis: 56%;
    padding:0.6em;
    border: 1px solid #cf0000;
    border-radius: 5px 0px 0px 5px;
  }
  
  .field>button {
    padding:0.6em 0.8em;
    background-color: #cf0000;
    color: #fff;
    border:none;
    border-radius: 0px 5px 5px 0px;
  }

  #keyword:focus{
    outline: none;
  }