.wrapper{
  /* border: 1px red solid; */
  width: 100%;
  min-height: 100vh;
  padding: 2rem 0;
}
.container{
  /* border: 1px red solid; */
  width: 100%;
  max-width: 75%;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 0.3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
  padding: 2rem;
}