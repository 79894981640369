.wrapper {
  /* border: 2px #fff solid; */
  width: 100%;
  max-width: 60%;
  height: 3rem;
  border-radius: 0.2rem;
  /* padding: 0.3rem; */
  overflow: hidden;
}
.searchBox {
  width: 100%;
  max-width: 100%;
  height: 100%;
  padding: 0.2rem;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
}
.search {
  border: none;
  width: 90%;
  height: 100%;
  padding: 0.8rem 0.6rem;
  font-size: 1rem;
  color: #333333;
  border: none;
  outline: none;
  background: transparent;
}
.search::placeholder {
  font-size: 1.1rem;
  color: #333333;
}
.searchIcon {
  border: none;
  /* margin: 0.5rem; */
  width: 10%;
  height: 100%;
  padding: 0.8rem 0.6rem;
  border-radius: 0.2rem;
  background: linear-gradient(to bottom, #cf0000, #fb3131);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.icon {
  color: #fff;
}
.mobileSearchBox {
  display: flex;
  justify-content: space-between;
  margin: 0;
  height: 2.5rem;
  position: sticky;
  top: 0;
}
.mobileSearchBox .search {
  width: 88%;
  height: 100%;
}
.mobileSearchBox .search::placeholder {
  font-size: 0.9rem;
}
.mobileSearchBox .searchIcon {
  width: 12%;
  height: 100%;
}

@media screen and (max-width: 1100px) {
  .searchBox {
    height: 2.5rem;
  }
  .search {
    width: 87%;
  }
  .searchIcon {
    width: 13%;
  }
}
