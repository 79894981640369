.semi-dark-bg{
    width:100%;
	/*-Lets Center the Spinner-*/
    position:fixed;
    left:0;
    right:0;
    top:0;
    bottom:0;
    z-index:9999;
    display: none; 
	background-color: #a6a6a6;
    opacity: 0.5;
}

.semi-dark-bg div{
  background:  url('../images/loading-img.gif') no-repeat center center;
  margin-top:15%;
  color:#a6a6a6
}

.total-transparen{
    width:100%;
	/*-Lets Center the Spinner-*/
    position:fixed;
    left:0;
    right:0;
    top:0;
    bottom:0;
    z-index:9999;
    display: none; 
	background: #fff url('../images/loading-img.gif') no-repeat center center;
}