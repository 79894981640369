.userImg{
height: auto;
width: 20%;
border-radius: 50%;
margin-bottom: 2%;
}
.userImg img{
    max-width: 100%;
    border-radius: 50%
}
.userImgCard{
    padding: 2%;
    background-color: #fff;
}
.userName h5{
    color: #cf0000
}
.wallVi{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.cardW{
    background: #fff;
}
.moneyCard{
    display: flex;
    justify-content: space-between;
}
.gridy{
    display: grid;
    align-items: center;
    padding-top: 15%;
}
.myOrdersCard{
    padding: 20px;
    background-color: #fff;
}
.orderPosition{
    /* border: 1px red solid; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
}
.Oposit a{
    display: grid;
    justify-items: center;
    text-decoration: none;
    color: #1c1b1c
}
.Oposit a:hover{
    
    color: #cf0000;
}
.inlineBlock {
    display: inline-block
}

.active-tab{
    color: crimson !important;
}


.trans-history .subject{
    flex-basis: 60%;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    font-size: 15px;
    font-weight: 40;
}

.trans-history .amount{
    font-size: 16px;
    font-weight: 40;
}

.history-wrap .date{
    font-size: 10px;
}

.history-wrap {
    padding: 0 0.7rem;
}

.history-wrap .fund-added{
    color: green ;
}

.history-wrap .fund-deducted{
    color: #cf0000;
}

.history-wrap > div{
    margin-top: 0.7rem;
}

.history-wrap .arrow{
    margin-right: 0.5rem;
}

.history-wrap .in{
    color: green ;
}

.history-wrap .out{
    color: #cf0000;
}


