.wrapper {
  /* border: 1px red solid; */
  width: 100%;
  height: 100%;
  background-color: #fff;

  display: flex;
  align-items: center;
  justify-content: center;
}
.container {
  /* border: 1px red solid; */
  width: 100%;
  max-width: 25rem;
  margin: 0 auto;
  padding: 1.5rem;
  border-radius: 0.3rem;
  box-shadow: 0 0.3em 0.5em rgba(0, 0, 0, 0.3);
  background-color: #f2f2f2;
}
.settingWrap {
  /* border: 1px red solid; */
  width: 100%;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.1s ease-in-out;
}
.settingWrap:hover {
  background-color: #fff;
}
.linkBox {
  /* border: 1px red solid; */
  width: 100%;
  max-width: max-content;
  color: #333333;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.settingName {
  font-size: 1.1rem;
  font-weight: 600;
}
.arrowForward{
  /* border: 1px red solid; */
  width: 100%;
  max-width: max-content;
}
.modalWrapper{
  /* border: 1px red solid; */
  width: 100%;
  height: 100%;
}
.title{
  /* border: 1px red solid; */
  width: 100%;
  text-align: center;
  margin-bottom: 1rem;
}
.formWrapper{
  /* border: 1px red solid; */
  width: 100%;
}
.phoneNumberBox{
  /* border: 1px red solid; */
  width: 100%;
  margin-bottom: 1.5rem;
}
.phoneNumber{
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 0.3rem;
}
.getCodeWrapper{
  /* border: 1PX RED solid; */
  margin-bottom: 1.5rem;
}
.getCodeContainer{
  /* border: 1px red solid; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.getCode{
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  gap: 0.2rem;
  cursor: pointer;
}
.inputField{
  outline: none;
  border: 1px grey solid;
  padding: 0.3rem;
}
.regErr{
  color: red;
}
.pinInputBox{
  /* border: 1px red solid; */
  width: 100%;
  margin-bottom: 1.5rem;
}
.pinInputContainer{
  /* border: 1px red solid; */
  width: 100%;
  display: flex;
  flex-direction: column;
}
.pinLabel{
  width: 100%;
  max-width: max-content;
  font-size: 1.1rem;
  font-weight: 600;
}
.pinInputField{
  width: 100%;
  outline: none;
  background: transparent;
  border: none;
  border-bottom: 1px grey solid;
  padding: 0.3rem;
  border-radius: 0.3rem;
  font-size: 1.5rem;
}
.pinInputField::placeholder{
  font-size: 1rem;
}
.btn{
  display: block;
  border: none;
  outline: none;
  width: 100%;
  max-width: 15rem;
  margin: 0 auto;
  background-color: #cf0000;
  color: #fff;
  font-size: 1.1rem;
  padding: 0.4rem 0;
  border-radius: 0.3rem;
}
