.wrapper{
  /* border: 1px red solid; */
  width: 100%;
  min-height: 90vh;
  max-width: 75%;
  margin: 1.5rem auto;
  background-color: #fff;
  border-radius: 0.3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.titleWrapper{
  /* border: 1px red solid; */
  width: 100%;
  max-width: 28rem;
  margin: 0 auto;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.title{
  /* border: 1px red solid; */
  width: 100%;
  max-width: max-content;
  margin: 0 auto;
  text-transform: capitalize;
  margin-bottom: 0.6rem;
}
.titleWrapper p{
  margin: 0;
  padding: 0;
  text-align: center;
  font-size: 1.1rem;
}
.formContainer{
  /* border: 1px red solid; */
  width: 100%;
  max-width: 24rem;
  margin: 0 auto;
  padding: 1.5rem;
  box-shadow: 0 0.3em 0.5em rgba(0, 0, 0, 0.3);
  border-radius: 0.3rem;
}
.formFieldWrapper{
  /* border: 1px red solid; */
  width: 100%;
}
.phoneInputBox{
  /* border: 1px red solid; */
  width: 100%;
  margin-bottom: 1.5rem;
}
.getCodeWrapper{
  /* border: 1PX RED solid; */
  margin-bottom: 1.5rem;
}
.getCodeContainer{
  /* border: 1px red solid; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.getCode{
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  gap: 0.2rem;
  cursor: pointer;
}
.inputField{
  outline: none;
  border: 1px grey solid;
  padding: 0.3rem;
}
.regErr{
  color: red;
}
.pinInputBox{
  /* border: 1px red solid; */
  width: 100%;
  margin-bottom: 1.5rem;
}
.pinInputContainer{
  /* border: 1px red solid; */
  width: 100%;
  display: flex;
  flex-direction: column;
}
.pinLabel{
  width: 100%;
  max-width: max-content;
  font-size: 1.1rem;
  font-weight: 600;
}
.pinInputField{
  width: 100%;
  outline: none;
  background: transparent;
  border: none;
  border-bottom: 1px grey solid;
  padding: 0.3rem;
  border-radius: 0.3rem;
  font-size: 1.5rem;
}
.pinInputField::placeholder{
  font-size: 1rem;
}
.btn{
  display: block;
  border: none;
  outline: none;
  width: 100%;
  max-width: 15rem;
  margin: 0 auto;
  background-color: #cf0000;
  color: #fff;
  font-size: 1.1rem;
  padding: 0.4rem 0;
  border-radius: 0.3rem;
}
