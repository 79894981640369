.wrapper{
  width: 100%;
  max-width: 12rem;
  height: 3rem;
}
.select{
  border: 2px red solid;
  width: 100%;
  height: 100%;
  border-radius: 0.3rem;
  padding: 0 0.5rem;
}
.option{
  font-size: 1rem;
}