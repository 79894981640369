.wrapper {
  /* border: 1px red solid; */
  width: 100%;
  padding: 0.5rem 0;
  background: linear-gradient(to bottom, #CF0000, #fb3131);
  position: sticky;
  top: 0;
  z-index: 10;
}
.wrapper .container{
  /* border: 1px #fff solid; */
  width: 100%;
  max-width: 75%;
  margin: 0.8rem auto;
  margin-bottom: 0.5rem;
  /* padding: 0; */
  display: flex;
  align-items: center;
  gap: 6rem;
  /* justify-content: space-between; */
  /* top: 0; */
}
.navRule{
  width: 100%;
  max-width: 80%;
  margin: 0 auto;
  color: #fff;
}
.nav{
  width: 100%;
  padding:  0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}
.option{
  width: 100%;
  max-width: max-content;
  text-transform: capitalize;
  color: #fff;
  font-size: 1rem;
  font-weight: 400;
  cursor: pointer;
}
.option:hover{
  color: #EDEDED;
}
@media screen and (max-width: 1450px) {
 .wrapper .container {
  /* border: 1px white solid; */
  width: 100%;
  max-width: 85%;
  }
}
/* @media screen and (max-width:1100px) {
  .wrapper .container{
  padding: 1rem 2rem;
}
}

@media screen and (max-width:650px) {
  .wrapper .container{
  display: none;
}
} */
