.container{
  /* border: 1px red solid; */
  width: 100%;
  margin-bottom: 4rem;
}
.container h1{
  text-transform: capitalize;
  margin-bottom: 1rem;
  text-align: center;
  font-size: 2rem;
}
.productsContainer{
  /* border: 1px red solid; */
  width: 100%;
  display: flex;
  /* justify-content: center; */
  gap: 1.2rem;
  /* overflow: auto; */
}
/* .productsContainer::-webkit-scrollbar{
  display: none;
} */
@media screen and (max-width:650px) {
  .container h1{
    font-size: 1.5rem;
  }
}