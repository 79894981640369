.wrapper{
  /* border: 1px red solid; */
  width: 100%;
  min-height: 100vh;
}
.container{
  /* border: 1px red solid; */
  width: 100%;
  max-width: 75%;
  margin: 2rem auto;
  padding: 1rem;
  background-color: #fff;
  border-radius: 0.3rem;
}
.title{
  /* border: 1px red solid; */
  width: 100%;
  max-width: max-content;
  margin: 0 auto;
  font-weight: 400;
  margin-bottom: 1rem;
}
.flexContainer{
  /* border: 1px red solid; */
  width: 100%;
  /* height: 80%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.topBar{
  /* border: 1px red solid; */
  border-top: 3px #cf0000 solid;
  width: 100%;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.topBar span{
  /* border: 1px red solid; */
  width: 100%;
  max-width: max-content;
  margin-top: -2px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.icon{
  color: #cf0000;
}
.topBar span:hover{
  color: #cf0000;
}
.registerBtn{
  display: block;
  outline: none;
  border: none;
  background-color: #cf0000;
  color: #fff;
  width: 100%;
  max-width: 15rem;
  margin: 0 auto;
  border-radius: 10rem;
  margin-bottom: 2rem;
}
.registerBtn .registerLink{
  display: block;
  width: 100%;
  color: #fff;
   font-size: 1rem;
  font-weight: 200;
  padding: 0.5rem;
  text-decoration: none;
}
.boxContainer{
  /* border: 1px red solid; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
  margin-bottom: 2rem;
}
.box{
  /* border: 1px red solid; */
  width: 100%;
  max-width: 28rem;
  height: 13rem;
  border-radius: 0.3rem;
  box-shadow: 0 0.3em 0.5em rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.box .iconBox .boxInBox{
  color: #cf0000;
}
.box h6{
  /* border: 1px red solid; */
  width: 100%;
  max-width: max-content;
  font-weight: 600;
  font-size: 1.1rem;
}
.box p{
  /* border: 1px red solid; */
  width: 100%;
  max-width: 25rem;
  text-align: center;
  font-size: 1.1rem;
}
.numbersBox{
  /* border: 1px red solid; */
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6rem;
  margin-bottom: 2rem;
}
.numbersBox div{
  /* border: 1px red solid; */
  width: 100%;
  max-width: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* gap: 0.5rem; */
}
.numbersBox div .num{
  color: #cf0000;
  font-size: 2rem;
  font-weight: 600;
  /* border: 1px red solid; */
  padding: 0;
}
.numbersBox div .numInfo{
  /* border: 1px red solid; */
  font-size: 1rem;
}
.affiliateBox{
  /* border: 1px red solid; */
  width: 100%;
  max-width: 35rem;
  height: 15rem;
  margin: 0 auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}
.affiliateBox h5, .affiliateBox p{
  /* border: 1px red solid; */
  width: 100%;
  max-width: max-content;
  color: #fff;
}
.affiliateBox h5{
  font-weight: 600;
}
.affiliateBox p{
  font-size: 1.1rem;
}
.redem{
  /* border: 1px red solid; */
  display: block;
  width: 100%;
  max-width: 15rem;
  color: #fff;
  text-decoration: none;
  background-color: #cf0000;
  padding: 0.5rem 0;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
.redem:hover{
  color: #fff;
  text-decoration: underline;
}