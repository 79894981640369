.loginNavWrapper{
    /* border: 1px red solid; */
    width: 100%;
    height: 6rem;
    background-color: #fff;
}
.loginNavbar{
    /* border: 1px red solid; */
    width: 100%;
    max-width: 75%;
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.loginLogoBox{
    /* border: 1px red solid; */
    width: 100%;
    max-width: 18rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.loginLogo{
    /* border: 1px red solid; */
    display: block;
    width: 100%;
    max-width: 12rem;
    user-select: none;
    cursor: pointer;
}
.loginLogo img{
    display: block;
    width: 100%;
}
.navTitle{
    text-transform: capitalize;
    font-size: 1.5rem;
    font-weight: 500;
    color: #333333;
}
.navLoginHelp{
    /* border: 1px red solid; */
    display: block;
    width: 100%;
    max-width: max-content;
    font-size: 1rem;
    color: #CF0000;
    cursor: pointer;
}