.home-wrapper{
    /* border: 1px red solid; */
    width: 100%;
    /* display: flex;
    gap: 1rem;
    padding: 1rem;
    padding-bottom: 0; */
}
.home-main-container{
    /* border: 1px red solid; */
    /* width: 100%;
    max-width: 75%; */
    margin: 0 auto;
    flex: 2.5;
}
.home-container{
    /* border: 1px red solid; */
    width: 100%;
    max-width: 85%;
    margin: 0 auto;
}

.homeButtons .buttn .active{
    color:#fff;
    background-color:#E2231A;
}

.homeButtons .buttn:hover{
    color:#fff;
    background-color:#E2231A;
}
.homeButtons .buttn button{
    border-radius: 0 !important;
}
.homeButtons{
    display: flex;
    justify-content: center;
    margin-top: 0;
    margin-bottom: 20px;
}
.homeButtons .buttn{
    margin: 0;
}
.homeButtons .buttn Link Button{
    padding: 5px 20px;
}
.homeBanner{
    margin-top: 3%;
}
.redlabel{
    height: 42px;
    width: 100%;
    background-color: #cf0000;
    border-radius: 10px;
    margin-top: 3%;
}
.redlabel .head{
    display: flex;
    justify-content: flex-start;
    color: #fff;
    align-content: center;
    padding: 10px;
}
.redlabel .head h5{
    margin-right: 10px;
}
.productImage{
    background-image: url(../images/bannerImg.png);
    height: 163px;
    background-position: center;
    margin-left: auto;
    margin-right: auto;
    border-radius: 10px 10px 0 0;
}
.productImage img{
    border-radius: 10px 10px 0 0;
}
.hotDealProduct{
    margin-top: 3% !important;
}
.cardDetail{
    background-color: #fff;
    padding: 4px 10px;
    border-radius: 0 0 10px 10px;
}
.cardDetail p{
    margin-bottom: 0px !important;
}

.cardDetail > div > p {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

