.wrapper{
  /* border: 1px red solid; */
  width: 100%;
  min-height: 100vh;
  padding: 2.5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.container{
   /* border: 1px red solid; */
  width: 100%;
  max-width: 70%;
  margin: 0 auto;
  border-radius: 0.3rem;
  background-color: #fff;
  padding-bottom: 2rem;
}
.title{
  /* border: 1px red solid; */
  width: 100%;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #cf0000;
  color: #fff;
  margin-bottom: 1.5rem;
}
.infoBox{
  /* border: 1px red solid; */
  width: 100%;
  padding: 0 2rem;
  margin-bottom: 2rem;
}
.noteContainer{
  /* border: 1px red solid; */
  width: 100%;
  margin-bottom: 1rem;
}
.noteContainer .noteTitle{
  font-size: 1.2rem;
  font-weight: 600;
}
.noteContainer .noteItemsBox{
  /* line-height: 2rem; */
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  margin: 0;
}
.noteContainer .noteItemsBox li, .noteContainer .noteItemsBox li b{
  font-size: 1.1rem;
}
.noticeDetailsBox{
  /* border: 1px red solid; */
  width: 100%;
}
.noticeDetailsList{
  /* border: 1px red solid; */
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.noticeDetailsList li{
  font-size: 1.1rem;
  line-height: 2rem;
  text-align: justify;
}
.noticeDetailsList li ul li b{
  font-size: 1.1rem;
  line-height: 2rem;
}
.linkBox{
  /* border: 1px red solid; */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.linkBox a{
  width: 100%;
  max-width: max-content;
  color: #cf0000;
  font-size: 1.1rem;
  text-decoration: none;
}
.linkBox a:hover{
  color: #cf0000;
  text-decoration: underline;
}
.linkBox .backLink{
  /* border: 1px red solid; */
  padding: 0.5rem;
  border-radius: 0.2rem;
  background-color: #cf0000;
  color: #fff;
}
.linkBox .backLink:hover{
  color: #fff;
  /* text-decoration: none; */
}