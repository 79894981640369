.wrapper {
  /* border: 1px red solid; */
  width: 100%;
  max-width: 75%;
  min-height: 80%;
  margin: 2rem auto;
  background-color: #fff;
  padding: 1rem;
  border-radius: 0.3rem;
}
.affiliateTitle {
  /* border: 1px red solid; */
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.affiliateTitle h2 {
  text-transform: capitalize;
}
.referralContainer{
  /* border: 1px red solid; */
  width: 100%;
  max-width: 50%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
}
.referralCode {
  /* border: 1px red solid; */
  width: 100%;
  max-width: max-content;
  /* margin: 0 auto; */
  /* margin-bottom: 1rem; */
  font-size: 1.2rem;
}
.referralCode span {
  font-weight: 600;
  font-size: 1.4rem;
  text-transform: capitalize;
}
.selectWrapper {
  /* border: 1px red solid; */
  width: 100%;
  max-width: 30%;
  /* margin: 0 auto; */
  /* margin-bottom: 2rem; */
}
.selectWrapper option{
  font-size: 1rem;
}
.balanceWrapper {
  /* border: 1px red solid; */
  width: 100%;
  max-width: 50%;
  height: 12rem;
  background-color: #cf0000;
  border-radius: 0.3rem;
  margin: 0 auto;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.totalBalance,
.availabeBalance {
  /* border: 1px #fff solid; */
  width: 100%;
  max-width: max-content;
  font-size: 1.2rem;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.amount {
  font-weight: 600;
  font-size: 1.3rem;
}
.withdrawalButton {
  display: block;
  border: none;
  outline: none;
  background-color: #cf0000;
  width: 100%;
  max-width: 15rem;
  margin: 0 auto;
  margin-bottom: 2rem;
  padding: 0.5rem 0;
  color: #fff;
  border-radius: 0.4rem;
}
.chartWrapper {
  /* border: 1px red solid; */
  width: 100%;
  max-width: 50%;
  margin: 0 auto;
  margin-bottom: 2rem;
   box-shadow: 0 0.3em 0.5em rgba(0, 0, 0, 0.3);
}
.refereeWrapper {
  /* border: 1px red solid; */
  width: 100%;
  max-width: 50%;
  margin: 0 auto;
  background-color: #ededed;
  border-radius: 0.3rem;
  padding: 1rem;
  margin-bottom: 2rem;
}
.refereeTitle {
  /* border: 1px red solid; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}
.refereeTitle h6 {
  /* border: 1px red solid; */
  width: 100%;
  max-width: max-content;
  font-size: 1.2rem;
  font-weight: 600;
  color: #cf0000;
}
.refLink {
  /* border: 1px red solid; */
  /* display: block; */
  width: 100%;
  max-width: max-content;
  color: #333333;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 600;
}
.refLink:hover {
  color: #cf0000;
}
.refereeListWrapper{
  width: 100%;
}
.refereeList{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}
.refereeList:last-child{
  margin-bottom: 0;
}
.noReferee {
  /* border: 1px red solid; */
  width: 100%;
  font-size: 1.2rem;
  text-align: center;
}
.historyWrapper {
  /* border: 1px red solid; */
  width: 100%;
  max-width: 65%;
  margin: 0 auto;
  background-color: #ededed;
  border-radius: 0.3rem;
  padding: 1rem;
  margin-bottom: 2rem;
}
.historyTitle {
  /* border: 1px red solid; */
  width: 100%;
  max-width: max-content;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 1rem;
  color: #cf0000;
}
.historyListWrapper{
  width: 100%;
  /* border: 1px red solid; */
  border-collapse: collapse;
}
/* .historyListWrapper tr:nth-child(even){
  background-color: #fff;
} */
.historyListWrapper tr:hover{
  background-color: #ddd;
}
.historyListWrapper thead, .historyListWrapper tbody{
  /* border: 1px red solid; */
    width: 100%;
}
.historyListWrapper tr{
  width: 100%;
}
.historyListWrapper th{
  width: 20%;
  text-transform: capitalize;
  font-size: 1.2rem;
  font-weight: 600;
}
.historyListWrapper td{
   font-size: 1.1rem;
   cursor: pointer;
}
.historyListWrapper th, .historyListWrapper td{
  /* border: 1px solid red; */
  padding: 1rem 0.5rem;
  text-align: center;
}
.historyListWrapper td .test{
  /* border: 1px red solid; */
  font-size: 1.2rem;
  width: 15rem;
  height: 1.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.transactionTime{
  width: 25%;
}
.transAmount{
  font-size: 1.1rem;
  /* font-weight: 600; */
}
.noHistory {
  /* border: 1px red solid; */
  width: 100%;
  font-size: 1.2rem;
  text-align: center;
}
.historyModalWrapper{
  width: 100%;
  max-width: 35rem;
  min-height: 20rem;
  background-color: #ededed;
  border-radius: 0.3rem;
  padding: 1.5rem;
}
.historyModalTitle{
  width: 100%;
  text-align: center;
  /* font-size: 1.3rem; */
  margin-bottom: 1.5rem;
  font-weight: 600;
}
.historyModaloverviewWrapper{
  /* border: 1px red solid; */
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-bottom: 1.5rem;
}
.overviewLabelWrapper{
  /* border: 1px red solid; */
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 1rem 0;
  border-radius: 0.5rem;
  background: #fff;
   box-shadow: 0 0.3em 0.5em rgba(0, 0, 0, 0.3);
}
.overviewValue{
  font-size: 1.3rem;
  font-weight: 600;
}
.overviewLabel{
  font-size: 1.2rem;
}
.historyModalStatusWrapper{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
.historyModalStatus{
  width: 100%;
  background-color: #fff;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
}
.historyModalStatus div{
  font-size: 1rem;
}
.orderSucces{
  color: green;
}
.orderPending{
  color: blue;
}
.orderCancel{
  color: red;
}
.modalWithdrawalWrapper{
  /* border: 1px red solid; */
  height: 100%;
}
.modalWithdrawalTitle{
  /* border: 1px red solid; */
  width: 100%;
  text-align: center;
  margin-bottom: 1.5rem;
  font-weight: 600;
}
/* .modalWithdrawalBox{
  border: 1px red solid;
} */
.modalInfoBox{
  /* border: 1px red solid; */
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.modalInfoBox p{
  margin: 0;
  padding: 0;
  font-size: 1.1rem;
}
.modalInfoBox p .modalBalance{
  font-size: 1.2rem;
  color: #cf0000;
  font-weight: 600;
  margin-left: 1px;
}
.topupform{
  /* border: 1px red solid; */
  width: 100%;
  margin: 1.5rem 0;
  display: flex;
  flex-direction: column;
  /* position: relative; */
}
.modalCurrency{
  font-size: 1.2rem;
  font-weight: 600;
  color: green;
  /* position: absolute; */
  bottom: 0.5rem;
  left: 1rem;
}
.modalLabel{
  /* border: 1px red solid; */
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.modalInput{
  outline: none;
  width: 100%;
  padding: 0.5rem;
  /* margin-left: 2rem; */
  font-size: 1.2rem;
  border: none;
  border-bottom: 2px grey solid;
}
.transBtn, .transBtnBlur{
   display: block;
  width: 100%;
  text-align: center;
  padding: 0.4rem 0;
  color: #fff;
  background-color: #cf0000;
  border: none;
  outline: none;
  font-size: 1.2rem;
  border-radius: 0.3rem;
  cursor: pointer;
}
.transBtnBlur{
  opacity: 0.5;
}

@media screen and (min-width:1600px) {
  .refereeWrapper{
    max-width: 30%;
  }
  .historyWrapper{
    max-width: 45%;
  }
}
