.account-settings-wrapper {
  /* border: 1px red solid; */
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 3rem;
}

.account-settings {
  /* border: 1px red solid; */
  width: 100%;
  max-width: 50%;
  height: 56%;
  margin: 0 auto;
  padding: 1.5rem;
  border-radius: 0.3rem;
  box-shadow: 0 0.3em 0.5em rgba(0, 0, 0, 0.3);
}
.account-settings-title {
  /* border: 1px red solid; */
  width: 100%;
  text-align: center;
  margin-bottom: 0.6rem;
  text-transform: capitalize;
}
.account-settings-map-container {
  /* border: 1px red solid; */
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  gap: 1.5rem;
  overflow-y: auto;
}
.account-settings-map-container::-webkit-scrollbar {
  display: none;
}
.addrress-wrap {
  /* border: 1px red solid; */
  width: 100%;
  padding: 1rem;
  border-radius: 0.3rem;
  background-color: #f2f2f2;
  /* margin-bottom: 1rem; */
  box-shadow: 0 0.3em 0.5em rgba(0, 0, 0, 0.3);
}
.addrress-wrap:last-child {
  margin-bottom: 0;
}
.settings-item {
  /* border: 1px red solid; */
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.3rem;
}
.settings-item div,
.settings-item div b {
  font-size: 1rem;
}

.log-out-wrap {
  width: 100%;
  max-width: 15rem;
}

.log-out-wrap button {
  width: 100%;
  border: 0;
  background-color: #fff;
  color: #cf0000;
  font-weight: bold;
  padding: 10px 0;
  border-top: 1px solid #ccc;
}

.empty-information {
  /* border: 1px red solid; */
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  font-weight: 600;
}

.add-acct-wrapper {
  /* border: 1px red solid; */
  width: 100%;
  height: 100%;
  /* padding: 1.5rem; */
}
.add-acct-title {
  /* border: 1px red solid; */
  width: 100%;
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 1rem;
}
.add-acct-form {
  /* border: 1px red solid; */
  width: 100%;
  margin-bottom: 2rem;
}
.add-acct-name-box {
  /* border: 1px red solid; */
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
}
.setting-name-label {
  /* border: 1px red solid; */
  width: 100%;
  font-size: 1rem;
  font-weight: 600;
}
.setting-name-input {
  border: none;
  outline: none;
  width: 100%;
  border-bottom: 1px grey solid;
  font-size: 1.1rem;
  padding: 0.5rem;
}
.select-bank-box {
  width: 100%;
}
.add-acct-btn {
  display: block;
  width: 100%;
  max-width: 15rem;
  outline: none;
  border: none;
  padding: 0.5rem 0;
  border-radius: 0.3rem;
  background-color: #cf0000;
  color: #fff;
  font-size: 1.2rem;
  margin: 0 auto;
}

/* my styles ends here */

.account-settings a {
  text-decoration: none;
}

.settings-category {
  border: 1px red solid;
  background-color: #fff;
  padding: 10px;
  margin-bottom: 15px;
}

.settings-category .setting-wrap {
  border-bottom: 1px solid #ddd;
  padding: 5px 0;
  margin-bottom: 10px;
}
.settings-category .setting-wrap div {
  font-size: 16px;
  color: #59595a;
}

.setting-icon {
  flex-basis: 10%;
  text-align: center;
}

.setting-wrap .setting-name {
  flex-basis: 90%;
}

.setting-wrap .link .view-more-arrow {
  flex-basis: 6%;
  text-align: center;
}

.setting-wrap .link {
  flex-basis: 100%;
}

/*address settings styling*/

.add-new-add button {
  background-color: crimson !important;
  color: #fff !important;
}

.add-info .name-phone {
  margin-bottom: 5px;
  font-size: 16px;
}

.add-info .name-phone span {
  font-size: 14px;
}

.add-info .name-phone span:last-child {
  margin-left: 20px;
}

.add-info > div {
  color: #59595a;
}

.add-info {
  flex-basis: 80%;
}

.add-arrow {
  flex-basis: 10%;
}

.address .default-add {
  color: crimson;
  margin-right: 5px;
}

.address span {
  font-size: 14px;
}
.add-form-wrap input {
  text-align: right;
  border: 0;
}

.add-form-wrap textarea {
  border: 0;
}

.set-default {
  padding: 10px;
  margin-top: 10px;
  background-color: #fff;
  color: #59595a;
}

.delete-add {
  padding: 5px 10px;
  margin-top: 10px;
  background-color: #fff;
}

.delete-add button {
  border: 0;
  background-color: #fff;
  color: crimson;
}

.phone_input .react-tel-input input {
  text-align: left;
}
