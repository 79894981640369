.wrapper{
  /* border: 1px red solid; */
  width: 100%;
  max-width: 70%;
  margin: 0 auto;
}
.title{
  /* border: 1px red solid; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 6.5rem;
  gap: 0.3rem;
}
.line{
  border: 1.5px #cf0000 solid;
  width: 20%;
  background-color: #cf0000;
}
.title h2{
  /* border: 1px red solid; */
  width: 100%;
  max-width: max-content;
  margin-bottom: 0;
}
.tableContainer{
  /* border: 1px red solid; */
  width: 100%;
  max-width: 95%;
  margin: 0 auto;
  margin-bottom: 3.5rem;
}
.shirtTitle{
  /* border: 1px red solid; */
  width: 100%;
  max-width: max-content;
  padding: 0.5rem;
  background-color: #cf0000;
  color: #fff;
  font-size: 1.2rem;
  margin: 0 auto;
  margin-bottom: 3rem;
}
.tableHolder{
  /* border: 1px red solid; */
  width: 100%;
  background-color: #f2f2f2;
}
.tableHolder thead, .tableHolder thead tr{
  width: 100%;
}
.tableHolder tr, .tableHolder thead th, .tableHolder tbody td{
  border-bottom: 1.2px #fff solid;
  border-right: 1.2px #fff solid;
}
.tableHolder thead tr th{
  /* border: 1px red solid; */
  width: 100%;
  max-width: 14%;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 1rem 0.5rem;
}
.tableHolder thead tr .shirtSize{
  width: 100%;
  max-width: 20%;
}
.tableHolder tbody, .tableContainer tbody tr{
  width: 100%;
}
.tableHolder tbody tr td{
  /* border: 1px red solid; */
  font-size: 1.1rem;
  font-weight: 200;
  text-align: center;
  padding: 1rem 0.5rem;
  text-transform: capitalize;
  color: gray;
}
.tableHolder tbody tr .tabledataTitle{
  color: #333333;
}
.tableTitle{
  border: 1px lightgray solid;
  width: 100%;
  padding: 1rem;
  font-size: 1.2rem;
  color: grey;
  border-radius: 0.3rem;
  margin-top: 2rem;
}