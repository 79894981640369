.App {
  text-align: center;
}

.App-logo {
  /* height: 40vmin; */
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.back-to-top {
  /* border: 1px red solid; */
  width: 100%;
  max-width: 3.5rem;
  height: 3.5rem;
  border-radius: 10rem;
  color: #fff;
  background-color: #cf0000;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  right: 2rem;
  bottom: -5rem;
  z-index: 12;
  cursor: pointer;
  transition: all 1s ease-in-out;
}
.show-arrow {
  bottom: 2rem;
}
.message-notification {
  /* border: 1px red solid; */
  width: 100%;
  max-width: max-content;
  position: fixed;
  right: 1rem;
  bottom: 4rem;
  z-index: 12;
  background-color: #fff;
  padding: 0.5rem;
  border-radius: 0.3rem;
  user-select: none;
  box-shadow: 0 0.3em 0.5em rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: all 0.5s ease-in-out;
  animation: notification 2s ease-in-out 5s infinite;
}
@keyframes notification {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.message-up {
  bottom: 7rem;
}
.messages-link {
  width: 100%;
  color: #333333;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
}
.message-notification span {
  font-size: 1.1rem;
  font-weight: 600;
}
.remove-messages{
  display: none;
}
.fixed-side-bar{
  /* border: 1px red solid; */
  background-color: #444851;;
  width: 100%;
  max-width: 3rem;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 11;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  box-shadow: 0 0.3em 0.5em rgba(0, 0, 0, 0.3);
}
.fixed-side-bar-link-box{
  /* border: 1px red solid; */
  width: 100%;
  padding: 0.2rem 0;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease-in-out;
}
.fixed-side-bar-link{
  /* border: 1px red solid; */
  width: 100%;
  color: #333333;
  text-decoration: none;
  text-align: center;
  background-position: -23px -225px;
  transition: all 0.3s ease-in-out;
}
.fixed-side-bar-link-box:hover{
  color: #fff;
  background-color: #cf0000;
}
.fixed-hover-box{
  /* border: 1px red solid; */
  height: 48px;
  line-height: 38px;
  width: 120px;
  position: absolute;
  z-index: 2;
  left: -150px;
  top: 0;
  background: #444851;
  color: #d8d8d8;
  /* text-align: center; */
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}
.fixed-side-bar-link-box:hover .fixed-hover-box{
  left: -120px;
  visibility: visible;
}
.box-content{
  /* border: 1px red solid; */
  font-size: 1.1rem;
}
.fixed-hover-box .arrow-direct{
  /* border: 1px red solid; */
  text-align: center;
  font-size: 2rem;
  position: absolute;
  right: -20px;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "inter", sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
}
* a {
  color: black;
  text-decoration: none;
  display: block;
}
.wrapper {
  background-color: #ededed;
  /* padding-top: 80px; */
  min-height: 80vh;
}
.form_input {
  border: none;
  outline: none;
}
.form_input:active {
  border: none;
  outline: none;
}
