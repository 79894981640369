.wrapper{
  /* border: 1px red solid; */
  width: 100%;
  /* max-width: 20rem; */
  height: 100%;
  background-color: #fff;
  flex: 1;
  border-radius: 0.4rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.accountIcon{
  /* border: 1px red solid; */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
}
.miniTitle{
  width: 100%;
  font-size: 1.3rem;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
}
.RgisterBox{
  /* border: 1px red solid; */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.register{
  /* border: 1px red solid; */
  width: 100%;
  text-align: center;
  padding: 0.6rem 1rem;
  border-radius: 10rem;
  background-color: #CF0000;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.register:hover{
  color: #fff;
}
.register:hover{
  background-color: #fa2222;
}
.register span{
  text-transform: capitalize;
  font-size: 1.2rem;
  font-weight: 500;
}