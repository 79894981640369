.cartBox{
  width: 100%;
  max-width: max-content;
  /* display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.1rem; */
  /* cursor: pointer; */
  color: #fff;
}
.cartLink{
  display: block;
  width: 100%;
  text-decoration: none;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.1rem;
}
.iconBox{
  width: 100%;
  max-width: max-content;
  position: relative;
}
.cartIcon{
  font-size: 2.5rem;
}
.circle{
  width: 100%;
  max-width: 1.7rem;
  height: 1.7rem;
  font-size: 0.85rem;
  font-weight: 600;
  border-radius: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: #CF0000;
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
}
.shop{
  font-size: 0.85rem;
}
.cartBox .cartLink:hover{
  color: #EDEDED;
}