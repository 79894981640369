.wrapper{
  /* border: 1px red solid; */
  width: 100%;
  height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.container{
  /* border: 1px red solid; */
  width: 100%;
  max-width: 75%;
  height: 80%;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 0.3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}
.imgBox{
  /* border: 1px red solid; */
  width: 100%;
  max-width: 20rem;
  margin: 0 auto;
  border-radius: 0.3rem;
  overflow: hidden;
}
.imgBox img{
  width: 100%;
  display: block;
}
.infoBox{
  /* border: 1px red solid; */
  width: 100%;
  max-width: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.infoBox p{
  /* border: 1px red solid; */
  font-size: 1.2rem;
  margin-bottom: 0;
}
.infoBox a{
  width: 100%;
  max-width: max-content;
  color: #cf0000;
  font-size: 1rem;
  text-decoration: none;
}
.infoBox a:hover{
  display: block;
  width: 100%;
  max-width: max-content;
  margin: 0 auto;
  text-decoration: underline;
  color: #cf0000;
}