.wrapper {
  /* border: 1px red solid; */
  width: 100%;
  max-width: 18.2rem;
  height: 19.5rem;
  flex: 0 0 auto;
  border-radius: 0.3rem;
  /* background-color: #EDEDED; */
  box-shadow: 0 0.3em 0.5em rgba(0, 0, 0, 0.3);
  overflow: hidden;
}
.productLink {
  /* border: 1px red solid; */
  display: block;
  width: 100%;
  height: 100%;
  padding: 1rem;
  text-decoration: none;
}
.background {
  display: block;
  width: 100%;
  height: 70%;
  border-radius: 0.3rem;
  margin-bottom: 5%;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}
.background img {
  width: 100%;
  height: 100%;
}
.background:hover {
  transform: scale(1.05);
}
.productInfo {
  /* border: 1px red solid; */
  width: 100%;
  height: 27%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0.3rem;
}
.name {
  /* border: 1px red solid; */
  width: 100%;
  height: 70%;
  color: #333333;
  font-size: 1rem;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.name:hover {
  color: #cf0000;
}
.price {
  /* border: 1px red solid; */
  height: 20%;
  font-size: 1rem;
  color: #cf0000;
  font-weight: 600;
}
@media screen and (max-width: 650px) {
  .wrapper {
    max-width: 15rem;
    height: 15rem;
  }
  .name {
    font-size: 0.7rem;
  }
  .price {
    font-size: 0.7rem;
  }
}
