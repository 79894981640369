/* for invoice page */
.process-invoice-payment-wrap {
  /* border: 1px red solid; */
  width: 100%;
  height: 100%;
}
.process-invoice-payment-wrap h2 {
  /* border: 1px red solid; */
  width: 100%;
  font-size: 1.2rem;
  text-align: center;
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: 1rem;
}
.process-invoice-payment-box {
  /* border: 1px red solid; */
  width: 100%;
}
.proccess-invoice-payment-title {
  /* border: 1px red solid; */
  width: 100%;
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 1rem;
}
.proccess-invoice-payment-title span {
  font-size: 1.2rem;
  font-weight: 600;
  color: #cf0000;
  margin: 0 0.1rem;
  text-transform: lowercase;
}
.processInvoicePayment-title-span {
  margin: 0;
  /* border: 1px red solid; */
  width: 100%;
  /* height: 3.5rem; */
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/* for others */
.process-payment-wrap {
  /* border: 1px red solid; */
  height: 100%;
  width: 100%;
  padding: 2rem 0;
}
.process-payment-container {
  /* border: 1px red solid; */
  width: 100%;
  max-width: 75%;
  height: 70vh;
  margin: 0 auto;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.process-payment-container > h2 {
  /* border: 1px red solid; */
  width: 100%;
  max-width: max-content;
  font-weight: 600;
  margin-bottom: 2rem;
  text-transform: capitalize;
}
.process-payment-box {
  /* border: 1px red solid; */
  width: 100%;
  max-width: 30rem;
  border-radius: 0.3rem;
  box-shadow: 0 0.3em 0.5em rgba(0, 0, 0, 0.3);
  padding: 1rem;
}
.proccess-payment-title {
  /* border: 1px red solid; */
  width: 100%;
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 2rem;
}
.proccess-payment-title span {
  font-size: 1.2rem;
  font-weight: 600;
  color: #cf0000;
  margin: 0 0.1rem;
  text-transform: lowercase;
}
.processPayment-title-span {
  margin: 0;
  /* border: 1px red solid; */
  width: 100%;
  height: 3.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.input-box {
  /* border: 1px red solid; */
  width: 100%;
  max-width: 65%;
  margin: 0 auto;
  margin-bottom: 1.5rem;
}
.password-label {
  display: block;
  /* border: 1px red solid; */
  width: 100%;
  max-width: max-content;
  margin: 0 auto;
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 0.3rem;
}

.pp-btn {
  width: 100%;
  max-width: 10rem;
  margin: 0 auto;
  border: none;
  border-radius: 0.3rem;
  background-color: #cf0000;
}
.pp-btn button {
  border: none;
  outline: none;
  display: block;
  padding: 0.5rem 0;
  background-color: transparent;
  width: 100%;
  font-size: 1.3rem;
  color: #fff;
}

.input-box input {
  border-color: green !important;
}

.walletErr {
  color: #cf0000;
  font-size: 0.8rem;
}
