.wrapper{
  /* border: 1px red solid; */
  width: 100%;
  /* height: 100%; */
}
.container{
  width: 100%;
  margin-bottom: 2rem;
}
.updateInputContainer{
  width: 100%;
}
.inputBox{
  /* border: 1px red solid; */
  width: 100%;
  display: flex;
  flex-direction: column;
}
.inputLabel{
   width: 100%;
  font-size: 1rem;
  font-weight: 600;
}
.nameInput{
  border: none;
  outline: none;
  width: 100%;
  border-bottom: 1px grey solid;
  font-size: 1.1rem;
  padding: 0.5rem;
}
.regErr{
  color: red;
}
.btn{
  display: block;
  width: 100%;
  max-width: 10rem;
  margin: 0 auto;
  outline: none;
  border: none;
  padding: 0.5rem 0;
  color: #fff;
  border-radius: 0.3rem;
  background-color: #cf0000;
  font-size: 1.1rem;
}