.wrapper{
  width: 100%;
  min-height: 100vh;
  padding: 2.5rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.container{
   /* border: 1px red solid; */
  width: 100%;
  max-width: 70%;
  margin: 0 auto;
  border-radius: 0.3rem;
  background-color: #fff;
  padding-bottom: 2rem;
}
.title{
  /* border: 1px red solid; */
  width: 100%;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #cf0000;
  color: #fff;
  margin-bottom: 1.5rem;
}
.detailsContainer{
  /* border: 1px red solid; */
  width: 100%;
  padding: 0 2rem;
  display: flex;
  flex-wrap: wrap;
  gap: 5%;
  margin-bottom: 2rem;
}
.detailBox{
  /* border: 1px red solid; */
  width: 100%;
  max-width: 45%;
  flex: 0 0 auto;
  box-shadow: 0 0.3em 0.5em rgba(0, 0, 0, 0.3);
  margin-bottom: 5%;
}
.noMargin{
  margin-bottom: 0;
}
.detailBox span{
  display: block;
  /* border: 1px red solid; */
  width: 100%;
  color: #cf0000;
  text-align: center;
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}
.imgBox{
  /* border: 1px red solid; */
  width: 100%;
  margin-bottom: 0.5rem;
}
.imgBox  img{
  width: 100%;
}
.detailBox p{
  /* border: 1px red solid; */
  width: 100%;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 0;
}
.linkBox{
  /* border: 1px red solid; */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.linkBox a{
  width: 100%;
  max-width: max-content;
  color: #cf0000;
  font-size: 1.1rem;
  text-decoration: none;
}
.linkBox a:hover{
  color: #cf0000;
  text-decoration: underline;
}
.linkBox .backLink{
  /* border: 1px red solid; */
  padding: 0.5rem;
  border-radius: 0.2rem;
  background-color: #cf0000;
  color: #fff;
}
.linkBox .backLink:hover{
  color: #fff;
}