.not-found-wrapper{
    /* border: 1px red solid; */
    width: 100%;
    min-height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.not-found-container{
    /* border: 11px red solid; */
    width: 100%;
    max-width: 50rem;
    display: flex;
    flex-direction: column;
}
.not-found-title{
    /* border: 1px red solid; */
    width: 100%;
    /* max-width: 30rem; */
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    margin-bottom: 2rem;
}
.not-found-info{
    /* font-weight: 600; */
    font-size: 3rem;
    color: gray;
}
.not-found-error{
    font-size: 5rem;
    /* font-weight: 600; */
    color: red;
}
.not-found-reason{
    /* border: 1px red solid; */
    width: 100%;
    max-width: 20rem;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
}
.not-found-reason span{
    line-height: 1.5rem;
}
.not-found-button{
    display: block;
    background-color: transparent;
    outline: none;
    border: 2px #cf0000 solid;
    widows: 100%;
    max-width: 10rem;
    border-radius: 0.5rem;
    padding: 0.5rem 0;
}