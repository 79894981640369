.wrapper{
  /* border: 1px red solid; */
  width: 100%;
  max-width: 75%;
  height: 30rem;
  display: flex;
  gap: 1rem;
  margin: 0 auto;
  margin-top: 1rem;
  margin-bottom: 2rem;
}
@media screen and (max-width: 1450px) {
  .wrapper {
  max-width: 85%;
  }
}