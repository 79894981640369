.wrapper{
  width: 100%;
  height: 100%;
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.container{
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  transition: all 1s ease-in-out;
}
.container:hover{
  animation-play-state: paused !important;
}
 .activeSlide{
  opacity: 1;
   transform: translateX(0);
}
.nextSlide {
  animation: nextSlide 1s forwards;
  opacity: 0;
}
@keyframes nextSlide {
  from{
    transform: translateX(0);
  }
  to{
    transform: translateX(100%);
  }
}
.lastSlide {
  animation: lastSlide 1s forwards;
  opacity: 0;
}
@keyframes lastSlide {
  from{
    transform: translateX(0);
  }
  to{
    transform: translateX(-100%);
  }
}
.arrows {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  transition: all 5s ease-out;
}
.arrowLeft,
.arrowRight {
  background-color: #fff;
  opacity: 0.5;
  padding: 0.7em 0.9em;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.arrowLeft:hover{
  opacity: 1;
}
.arrowRight:hover{
  opacity: 1;
}
.arrowLeft {
  padding-left: 1.5em;
  transform: translateX(-5em);
  transition: all 0.5s ease-out;
}
.wrapper:hover .arrowLeft {
  transform: translateX(-0.8em);
}
.arrowRight {
  padding-right: 1.5em;
  transform: translateX(5em);
  transition: all 0.5s ease-out;
}
.wrapper:hover .arrowRight {
  transform: translateX(0.8em);
}
.arrowFont {
  font-size: 1.8rem;
}


@media screen and (max-width:650px) {
  .wrapper{
    height: 18rem;
  }
}