.item-wrapper{
  min-height:100vh;
  background-color: #ededed;
  /* border: 11px red solid; */
}

.item-container{
  height:100%;
  background-color: #ffffff;
  width: 100%;
  max-width: 80%;
  margin:auto;
  padding:3rem 1rem;
}

.item-box{
  min-height:50vh;
  /* border: 11px red solid; */
  margin-bottom: 3rem;
}

.item-box > div{
  min-height:50vh;
}

.item-image{
  width: 40%;
  height: 40%;
  /* border: 1px red solid; */
  margin-right: 1.5rem;
  display: flex;
  flex-direction: column;
   position: sticky;
  top: 8.5rem;
  /* z-index: 200; */
}



.suggested-item{
  border:1px solid #999999;
  width: 14%;
  border-radius: 0.7em;
  padding: 0 0 0 1em;
}



.large-image{
  height: 35rem;
  width:100%;
  padding:2px;
    
}

.large-image img{
  width:100%;
  height:100%
}

 .img-container img{
  width:100%;
  height:100%;
  
}

.small-image{
  height: 8rem;
  width: 100%;
  padding:0.5rem;
  padding-top:1rem;
  border: 1px solid red;
}  

  .img-container{
    height:6rem;
    width:6rem;
    border: 1px solid #dddddd;
    padding:0.1rem;
    cursor:pointer;
    float: left !important;
  }

  /* .img-container:hover{
     
  } */

    .active-items{
      border: 1px solid #E2231A !important;
    }

    .item-spec-buyNow-wrapper{
   /* border: 1px red solid; */
  width: 60%;
  height: 100%;
}
.item-props{
  /* border: 1px red solid; */
  width: 100%;
   height: 33rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: all 1s ease-in-out;
}
.item-props-open{
  height: 100%;
}

    .item-title{
      font-weight:600;
    }

    .price-rage-wrap{
      background-color: #f9f9fa;
      padding:3px 10px;
      margin:1rem 0rem;

    }

    

    .price-rage-inner-left{
      flex-basis: 25%;
    }
    .price-rage-inner-right{
      flex-basis: 75%;
    }

    .price-wrap div:first-child{
      color:#E2231A;
      font-size: 1.4rem;
    }
  
    .price-wrap div:last-child{
      font-size: 0.9em;
    }

    .price-rage-inner-left div:first-child{
      color:#E2231A;
      font-size: 1.4rem;
    }
  
    .price-rage-inner-left div:last-child{
      font-size: 0.9em;
    }

    .price-wrap{
      flex-basis: 30%;
      text-align: center;
    }
    .spec-wrap-box-arrow{
  /* border: 1px blue solid; */
  width: 100%;
  max-width: 4rem;
  padding: 0.2rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ededed;
  margin-left: 7.8rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
}

    .spec-wrap{
      border-top:1px solid #f0f0f0;
      border-bottom:1px solid #f0f0f0;
    }
    .props-wrap{
      
      border-bottom:1px dashed #f0f0f0;
      padding:1.5rem 0.1rem 1rem;
    }

    .props-name{
      flex-basis: 15%;
      color:#999999;
      line-height: 2.5rem;
      padding:10px;
    }

    .props-value-wrap{
      flex-basis: 85%;
      flex-wrap: wrap;
    }

    .value-img img{
      width: 2rem;
      height: 2rem;
      border-radius: 2px;
    }

    .value-wrap{
      border: 1px solid #cccccc;
      padding:1px;
      cursor: pointer;
      margin-right: 0.7rem;
      margin-bottom: 0.5rem;
      position:relative;
    }

    .value-wrap:hover{
      border: 1px solid #E2231A;

    }

    .value-text{
      line-height: 1.3rem;
      padding:5px 2px;
      font-size: 13px;
      color:#333333;
      min-width: 2rem;
      height: 2rem;
    }

    .props-2-container{
      border-bottom: 1px dashed #f0f0f0;;
      padding: 10px 0;
    }

    .props-2-container:last-child{
      border-bottom: 0;
    }

    .props-2-value{
      flex-basis: 35%;
      line-height: 40px;
      font-weight: bold;
    }

    .props-2-value div{
      min-width:'5em'
    }
    
    .props-price{
      flex-basis: 20%;
      text-align: center;
      line-height: 40px;
      font-weight: bold;
    }

    .props-quantity-available{
      flex-basis: 20%;
      text-align: center;
      line-height: 40px;
      color:#999999;
    }

    .props-qty{
      flex-basis: 25%;
      text-align: center;
      padding-top: 5px;
    }

    .props-2-wrap{
      padding:1.5rem 0.1rem 1rem;
    }

    .qty-input{
      width: 50%;
      height:2rem;
      text-align: center;
      border: 1px solid #f0f0f0;
    }

    .qty-input:focus{
      outline: none;
    }

    .qty-btn{
      width: 24%;
      height:2rem;
      background-color: #f0f0f0;
      border:0;
    }

    .props-2-img img{
      width: 35px;
      height: 35px;
      cursor: pointer;
    }

    .props-2-img{
      margin-right: 15px;
    }

    .total-summary{
      margin: 2rem 0 1em;
      padding:1rem 0.4rem;
      color:#999999;
      background-color: #f5f5f5;
    }

    .total-summary div{
      margin-right: 2rem;
    }

    #amount{
      color: #E2231A;
      font-size: 1.5rem;
      font-weight: 700;
    }

    .total-qty{
      line-height: 3;
    }

    .local-shipping-fee{
      line-height: 3;
    }

    #shipping-fee{
      color: #E2231A;
      font-weight: 700;
    }

   .qty-badge{
    position:absolute;
    right:0;
    top:-20px;
    color:#ffffff;
    background-color:#E2231A;
    padding:3px;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    text-align: center;
   }

   .prop-name{
    font-weight: bold;
   }

  
  
  .recommend-head{
     padding: 0.5em;
     border:1px solid #dddddd;
     border-top-left-radius: 0.7em;
     border-top-right-radius: 0.7em;
     border-bottom: 0;
     margin-top: 1.5em;
     font-size: 18px;
     font-weight: 500;
     color:#999;
   }

   .recommend-body{
    padding: 0.5em;
    border:1px solid #dddddd;
    border-bottom-left-radius: 0.7em;
    border-bottom-right-radius: 0.7em;
    border-top: 0;

   }

   .recommend-card-wrapper{
    flex-basis: 12%;
    margin-right: 1em;
    cursor: pointer;
   }

   .recommend-card img{
    width: 100%;
    height: 15em;
    border-radius: 0.7em;
   }

   .recommend-card .title{
     font-size: 12px;
     color:#333333;
     padding: 0.3em 0.2em
   }

   .recommend-card .amount{
    color:#E2231A;
    font-weight: bolder;
   }

   .recommend-card  .sales{
    font-size: 11px;
     color:#999999;
   }

   .related-wrapper{
     margin-top: 1.5em;
   }

   .related-wrapper .left{
    flex-basis: 15%;
    border:1px solid #dddddd;
    border-radius: 0.7em;
    
   }

   .related-wrapper .left img{
    flex-basis: 15%;
   }

   .related-wrapper .left .recommend-card-wrapper{
    margin-right: 0;
   }

   .related-wrapper .left .recommend-card-wrapper .recommend-card{
     width: 80%;
     margin: auto;
     margin-top: 0.7em;
   }

   .related-wrapper .right{
    /* border: 1px red solid; */
    flex-basis: 82.5%;
    width: 100%;
    padding: 0.5em;
    border:1px solid #dddddd;
    border-radius: 0.7em;

   }

   .related-wrapper .left .related-head{
    font-weight: 700;
    color:#9a9a9a;
    text-align: center;
    padding: .5em;
    border-bottom: 1px solid #dddddd;
   }


   #scroll-progress {
    background: green;
    height: 6px;
    margin-top: 32px;
    width: 0;
  }
  
  #scroller{
    /* border: 1px red solid; */
    list-style: none;
    padding: 0;
    margin: 0;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  #scroller::-webkit-scrollbar {
    display: none;
  }
  #scroller li {
    /* border: 1px blue solid; */
    display: inline-block;
    height: 50px;
    width: 50px;
    border-radius: 3px;
    margin-right: 10px;
    cursor: pointer;
    margin-top: 1rem;
    flex: 0 0 auto;
  }

  #scroller li img{
    height: 100%;
    width: 100%;
    border-radius: 2px;

  }
  #scroller li:hover{
    border: 1px solid #E2231A;

  }

  .buyNow-addTotCart{
    /* border: 1px red solid; */
    padding: 0.7em 0.5em;
      /* padding: 0.7em 0.5em; */
  position: sticky;
  bottom: 0;
  }

   .buyNow-addTotCart .buy-now{
    border: 0;
    margin-right: 1em;
    color: #ffffff;
    border-radius: 0.4em;
    background-color: #E2231A;
    position: relative;
  }

  .buyNow-addTotCart .buy-now:hover,
  .buyNow-addTotCart .buy-now:active,
  .buyNow-addTotCart .buy-now:focus
  {
    background-color:#b41c14;
    color: #ffffff;
  }

  .buyNow-addTotCart .add-cart{
    border: 1px solid #E2231A;
    margin-right: 1em;
    border-radius: 0.4em;
    color: #E2231A;

  }

  .buyNow-addTotCart .add-cart:hover{
    border: 1px solid #E2231A;
    color: #E2231A;
  }

  .buyNow-addTotCart .add-favourite{
    line-height: 35px;
    font-size: 16px;
  }

  .add-favourite .fa-heart{
    font-size: 20px;
    color: #E2231A;
  }
  .item-success{
    width: 20rem;
    position: fixed;
    margin: auto;
    top: 15%;
    left: 40%;
  }

 .min-order{
    color: #E2231A;
    font-size: 11px;
  }

  /* play icon */
  .play-icon{
    width: 100%;
    max-width: 4rem;
    height: 4rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
  }

  /* play icon */
  .play-icon-sm{
    width: 100%;
    max-width: 2rem;
    height: 2rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
  }

