.ModalTitle{
  border-top: 2px lightgrey solid;
  border-bottom: 2px lightgrey solid;
  width: 100%;
  padding: 1rem 1.5rem;
  margin-bottom: 1rem;
}
.ModalTitle::first-letter{
  text-transform: uppercase;
}
.infoBox{
  /* border: 1px red solid; */
  width: 100%;
  padding: 1rem 1.5rem;
}
.modalInfo{
  /* border: 1px red solid; */
  width: 100%;
  padding: 0;
  margin: 0;
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
}
.modalInfo::first-letter{
  text-transform: uppercase;
}
.info2{
  width: 100%;
  font-size: 1.1rem;
  font-weight: 600;
}
.info2::first-letter{
  text-transform: uppercase;
}
.modalDate{
  border-top: 2px lightgrey solid;
  border-bottom: 2px lightgrey solid;
  padding: 1rem 1.5rem;
  font-size: 1.1rem;
  font-style: italic;
}