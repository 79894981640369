.wrapper{
  /* border: 1px red solid; */
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.container{
  /* border: 1px red solid; */
  width: 100%;
  max-width: 50%;
  margin: 0 auto;
  border-radius: 0.3rem;
  background-color: #fff;
  padding: 1rem;
}
.title{
  /* border: 1px red solid; */
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  text-transform: capitalize;
  margin-bottom: 1rem;
}
.profileInfo{
  /* border: 1px red solid; */
  width: 100%;
}
.detailEdith{
  /* border: 1px red solid; */
  width: 100%;
  background-color: #f2f2f2;
  padding: 0.6rem 1.2rem;
  margin-bottom: 0.7rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
   cursor: pointer;
}
.detailBoxEdith{
  /* border: 1px red solid; */
  width: 100%;
  max-width: max-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.detailBox{
  /* border: 1px red solid; */
  width: 100%;
  padding: 0.6rem 1.2rem;
  margin-bottom: 0.7rem;
  background-color: #f2f2f2;
  border-radius: 0.4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.detailBox:hover, .detailEdith:hover{
  background-color: #ddd;
}
.userDetail, .userDetailTitle{
  font-size: 1rem;
  text-transform: capitalize;
}
.userDetailTitle{
  font-weight: 600;
}
.userDetail{
  font-size: 1.2rem;
}
.edithBox{
  /* border: 1px red solid; */
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 10rem;
  background-color: #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
}
.detailEdith:hover .edithBox{
  background-color: #f2f2f2;
}
.activeUser{
  color: green;
}