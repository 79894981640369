.success-alert{
    margin: 0 15%;
    padding: 2rem;
    background-color: #fff;
    min-height: 75vh;
  }

  .payment-complted{
    width: 340px;
    margin: 10% auto;
    text-align: center; 
}

.success-icon svg{
    color:green;
    font-size: 100px;
}

.tc span{
    font-size: 25px !important;
    font-weight: bold;
}

.tc span{
    font-size: 16px; 
}

 .payment-complted button{
      border: none;
      background-color: #E2231A;
      padding: 8px;
      font-size: 16px;
      font-weight: 400;
      cursor: pointer;
      color: #FFFFFF;
  }


    

  