.invoice-wrap{
    background-color: #ffff;
    min-height: 91vh;
    padding: 2.5rem 0.6rem;
}

.invoice-list{
    margin-bottom: 10px;
    padding: 10px 5px;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 5px;
}

.invoice-list > div:nth-child(1){
    color: crimson;
    font-size: 14px;
    font-weight: bold;
}

.invoice-list > div:nth-child(2){
    color: #59595a;
    font-size: 12px;
    font-weight: bold;
  
}

.invoice-list > div:nth-child(3){
    color: #59595a;
    font-size: 12px;
}
.invoice-list > div:nth-child(4){
    color: #59595a;
    font-size: 12px;
}

.invoice-list > div:nth-child(5) > button{
    border: none;
    padding: 5px;
    color: #fff;
    background-color: crimson;
    border-radius: 5px;
}

.invoice-list > div{
    flex-basis: 20%;
    text-align: center;
}
.invoice-heading > div{
    flex-basis: 20%;
    text-align: center;
}