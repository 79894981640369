.termsCondition{
    margin-top: 5px;
    display: flex;
}
.termsCondition p{
    margin-bottom: 0;
    padding-left: 1%;
}
.signin{
    margin-top: 2%
}
.phoneField{
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    border: 1px #cf0000 solid !important;
}
.PhoneInputInput {
    border: none !important;
}
.lgbtn:hover{
color:#cf0000 !important;
}
.lgbtn:hover{
color:#cf0000 !important;
}
.phoneField input{
    border: none;
}
.loginForm input:active{
    border: none !important;
    outline:none !important;
  }
  .loginForm input{
    border: none;
    outline:none
  }

  .regErr{
    color:#cf0000 !important;
    font-size: 11px;;
  }

  .instructions {
    font-size: 0.75rem;
    border-radius: 0.5rem;
    background: #000;
    color: #fff;
    padding: 0.25rem;
    position: relative;
    bottom: -10px;
}

.instructions > svg {
    margin-right: 0.25rem;
}

.offscreen {
    position: absolute;
    left: -9999px;
}

.hide {
    display: none;
}

.valid {
    color: limegreen;
    margin-left: 0.25rem;
}

.invalid {
    color: red;
    margin-left: 0.25rem;
}