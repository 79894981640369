.wrapper{
  /* border: 1px red solid; */
  height: 100%;
  /* width: 100%; */
  /* max-width: 16%; */
  /* height: 20%; */
    border-radius: 0.4rem;
  flex: 1;
  display: flex;
  flex-direction: column;
   /* padding: 1.5rem 0; */
  /* padding: 1rem 0; */
  background-color: #fff;
  /* border-radius: 0.4rem; */
  /* position: fixed; */
  /* left: 0;
  top: 15rem;
  z-index: 10; */
}
.container{
  /* border: 11px red solid; */
   border-radius: 0.4rem;
   height: 48vh;
   /* overflow: hidden; */
  /* padding: 1.5rem 0; */
  /* top: 12rem;
  bottom: 8.5rem; */
   position: fixed;
  /* background-color: #fff; */
}
.title{
  /* border: 1px red solid; */
  width: 100%;
  font-size: 1.5rem;
  text-transform: capitalize;
  font-weight: 600;
  margin-bottom: 1rem;
  padding: 0 1rem;
}
.options{
  /* border: 1px red solid; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: auto;
}
.options::-webkit-scrollbar{
  display: none;
}
.option{
  /* border: 1px red solid; */
  width: 100%;
  text-transform: capitalize;
  font-size: 0.85rem;
  font-weight: 500;
  cursor: pointer;
  padding: 0.3rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: black;
  transition: all 0.2s ease-in-out;
}
.option:hover{
  background: #fff;
   box-shadow: 0 0.3em 0.5em rgba(0, 0, 0, 0.3);
}
.icon{
  color: gray;
}
