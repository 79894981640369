.wrapper{
  /* border: 1px red solid; */
  width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.container{
  /* border: 1px red solid; */
  width: 100%;
  /* margin: 0 auto; */
  border-radius: 0.3rem;
  background-color: #fff;
  min-height: 30rem;
  padding: 2rem 0;
}
.titleBox{
  /* border: 1px red solid; */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin-bottom: 3rem;
}
.title{
  text-transform: capitalize;
  margin-bottom: 0;
}
.titleInfo{
  /* border: 1px red solid; */
  margin-bottom: 0;
  font-size: 1.2rem;
  color: #333333;
  width: 100%;
  max-width: 72%;
  margin: 0 auto;
  text-align: center;
}
.overviewBox{
  /* border: 1px red solid; */
  width: 100%;
  max-width: 95%;
  margin: 0 auto;
  margin-bottom: 2rem;
}
.overview{
  /* border: 1px red solid; */
  text-transform: capitalize;
  font-weight: 600;
  color: #cf0000;
  width: 100%;
  max-width: max-content;
  margin-bottom: 1rem;
}
.overviewHighlightBox{
  width: 100%;
  /* border: 1px red solid; */
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2%;
}
.overviewHighlight{
  /* border: 1px red solid; */
  width: 100%;
  max-width: 23%;
  height: 6rem;
  flex: 0 0 auto;
  border-radius: 0.4rem;
  box-shadow: 0 0.3em 0.5em rgba(0, 0, 0, 0.3);
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.highlightInfo{
  /* border: 1px red solid; */
  width: 100%;
  max-width: 45%;
  height: 100%;
  border-radius: 0.3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.highlightInfo .orderNum{
  font-size: 2rem;
  font-weight: 600;
  color: #333333;
}
.highlightInfo .highlightDescription{
  font-size: 1rem;
  text-transform: capitalize;
  color: #333333;
  font-weight: 500;
}
.highlightIconBox{
  /* border: 1px red solid; */
  width: 100%;
  max-width: 35%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #cf0000;
  border-radius: 0.3rem;
}
.tableContainer{
  /* border: 1px red solid; */
  width: 100%;
  box-shadow: 0 0.3em 0.5em rgba(0, 0, 0, 0.3);
  border-radius: 0.3rem;
}
.tableContainer thead, .tableContainer tr{
  width: 100%;
  /* border: 1px red solid; */
}
.tableContainer tr{
  border-bottom: 1px grey solid;
}
.tableContainer thead tr{
  background-color: #f2f2f2;
}
.tableContainer thead tr th{
  /* border: 1px red solid; */
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  padding: 1rem 0.3rem;
  text-transform: capitalize;
  color: #333333;
}
.tableContainer thead tr .largerWidth{
  width: 100%;
  max-width: 12%;
}
.tableContainer thead tr .date{
  width: 100%;
  max-width: 13%;
}
.tableContainer thead tr .name{
  width: 100%;
  max-width: 15%;
}
.tableContainer thead tr .phone{
  width: 100%;
  max-width: 14%;
}
.tableContainer thead tr .package{
  width: 100%;
  max-width: 10%;
}
.tableContainer thead tr .status{
  width: 100%;
  max-width: 13%;
}
.tableContainer thead tr .more{
  width: 100%;
  max-width: 5%;
}
.tableContainer tbody{
  width: 100%;
}
.tableContainer tbody tr{
  width: 100%;
  cursor: pointer;
}
.tableContainer tbody tr:hover{
  background-color: #ddd;
}
.tableContainer tbody:nth-child(odd){
  background-color: #f2f2f2;
}
.tableContainer tbody tr td{
  /* border: 1px red solid; */
  font-size: 1rem;
  font-weight: 200;
  text-align: center;
  padding: 1rem 0.3rem;
  text-transform: capitalize;
  color: #333333;
}
.tableContainer tbody tr .arrived{
  color: blue;
}
.tableContainer tbody tr .delivered{
  color: green;
}
.tableContainer tbody tr .processing{
  color: rgb(187, 187, 2);
}
.tableContainer tbody tr .notReceived{
  color: red;
}
.pageBox{
  /* border: 1px red solid; */
  width: 100%;
  max-width: 95%;
  margin: 0 auto;
}
.modalTitle{
  /* border: 1px red solid; */
  width: 100%;
  max-width: max-content;
  margin: 0 auto;
  font-weight: 500;
  text-transform: capitalize;
  margin-bottom: 1.5rem;
}
.modalDetailBox{
  /* border: 1px red solid; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  gap: 1rem;
}
.modalDetail{
  /* border: 1px red solid; */
  width: 100%;
  padding: 0.5rem;
  background-color: #f2f2f2;
  border-radius: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.detailName{
  /* border: 1px red solid; */
  width: 100%;
  max-width: max-content;
  font-size: 1.2rem;
  color: #333333;
}
.detailValue{
  /* border: 1px red solid; */
  width: 100%;
  max-width: max-content;
  font-size: 1.3rem;
  color: #cf0000;
  font-weight: 600;
}
.detailValueIcon{
  /* border: 1px red solid; */
  width: 100%;
  max-width: max-content;
  font-size: 1.3rem;
  font-weight: 600;
  background-color: #ddd;
  padding: 0.3rem;
  cursor: pointer;
  border-radius: 0.2rem;
}
