.wrapper{
  width: 100%;
  max-width: 40rem;
}
.title{
  text-transform: uppercase;
  font-size: 2.2rem;
  margin-bottom: 1.5rem;
}
.title span{
  color: #cf0000;
  font-size: 2.2rem;
}
.addressBox{
  margin-bottom: 1.5rem;
  /* user-select: none; */
}
.addressBox h2{
  color: #cf0000;
  text-transform: uppercase;
  font-weight: bolder;
  margin-bottom: 0.2rem;
  font-size: 1.4rem;
}
.addressBox p{
  text-transform: capitalize;
  line-height: 1.5rem;
  font-size: 1.2rem;
}