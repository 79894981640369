.sort-btn button {
  border-radius: 0 !important;
  background-color: #c9c9c9;
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  border: 0 !important;
}

.sort-btn button:hover {
  background-color: #e2231a;
  color: #ffffff;
}

.sort-btn {
  margin-right: 10px;
}

.sort-btn .active {
  color: #fff !important;
  background-color: #e2231a !important;
}
.shop-search-item-container {
  /* border: 1px red solid; */
  /* width: 100%; */
  width: 95%;
  margin: 0 auto;
  /* height: 100%; */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1.2rem;
}
.product-list-item-wrapper {
  /* border: 1px red solid; */
  width: 100%;
  max-width: 18.9rem;
  height: 20rem;
  flex: 0 0 auto;
  border-radius: 0.3rem;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}
.product-list-item-wrapper:hover {
  box-shadow: 0 0.3em 0.5em rgba(0, 0, 0, 0.3);
}
.product-item-link {
  display: block;
  width: 100%;
  height: 100%;
  padding: 1rem;
  text-decoration: none;
}
.product-item-background {
  display: block;
  width: 100%;
  height: 65%;
  border-radius: 0.3rem;
  margin-bottom: 5%;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}
.product-item-background img {
  width: 100%;
  height: 100%;
}
.product-item-Info {
  /* border: 1px red solid; */
  width: 100%;
  height: 32%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 0.3rem;
}
.product-item-name {
  /* border: 1px red solid; */
  width: 100%;
  height: 40%;
  color: #333333;
  font-size: 0.85rem;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.product-item-name:hover {
  color: #cf0000;
}
.product-item-price,
.product-item-sales {
  /* border: 1px red solid; */
  height: 20%;
  font-size: 1rem;
  color: #cf0000;
  font-weight: 600;
}
.product-item-sales {
  color: #333333;
}
.loading-more {
  width: "100%";
  font-size: 1rem;
  text-align: center;
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
}
