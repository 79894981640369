.wrapper{
  /* border: 1px red solid; */
  width: 100%;
  max-width: 75%;
  margin: 0 auto;
  /* padding: 4rem 0; */
}
.container{
  /* border: 1px red solid; */
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 2.5rem;
  background-color: #fff;
  border-radius: 0.5rem;
  padding: 1rem;
}
.title{
  /* border: 1px red solid; */
  width: 100%;
  margin-bottom: 0.5rem;
  text-transform: capitalize;
  color: #cf0000;
  font-size: 1.5rem;
}
.productsContainer{
  /* border: 1px red solid; */
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  /* justify-content: center; */
  gap: 1.2rem;
  /* overflow: auto; */
}
@media screen and (max-width: 1450px) {
  .wrapper {
  max-width: 85%;
  }
}