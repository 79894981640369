.LogHeight {
  border: 1px blue solid;
  height: 88vh;
  /* padding:5%; */
  background-color: #ededed;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loginWrapper {
  /* border: 1px red solid; */
  width: 100%;
}
.login-body {
  /* border: 1px red solid; */
  width: 100%;
  height: 80vh;
  background: #cf0000;
}
.login-body-conatiner {
  /* border: 1px red solid; */
  width: 100%;
  max-width: 80%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.logImg-box {
  /* border: 1px red solid; */
  width: 100%;
  max-width: 55%;
  height: 85%;
}
.logImg-box a {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 0px;
}
.logImg-box img {
  width: 100%;
  height: 100%;
  border-radius: 0px;
}
.loginForm {
  /* border: 1px blue solid; */
  width: 100%;
  max-width: 35%;
  /* height: 75%; */
  background-color: #fff;
  border-radius: 0.3rem;
  overflow: hidden;
}
.signupForm {
  height: 90%;
}
.Login {
  margin-top: 5%;
  background-color: #ededed;
}

.loginForm .formLog {
  width: 100%;
  /* height: 100%; */
  /* background-color: #fff; */
  padding: 2rem;
  /* border-radius: 0.3rem; */
}

.login-title {
  color: #cf0000;
  margin-bottom: 1rem;
}
.LogButton {
  display: flex;
  justify-content: space-evenly;
  margin-top: 1%;
  width: 100%;
}

.LogButton .btn,
a {
  width: 100%;
}
.or-box {
  /* border: 1px red solid; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5rem 0;
  gap: 1rem;
}
.or-line {
  width: 100%;
}
.or {
  color: lightgrey;
}
.google {
  border: 1px #bdbdbd solid;
  width: 100%;
  border-radius: 0.5rem;
  padding: 0.3rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;
}
.google span {
  font-size: 1.2rem;
  font-weight: 100;
}
.signup-link-box {
  /* border: 1px red solid; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
}
.signup-link-box span {
  /* border: 1px red solid; */
  width: 100%;
  max-width: max-content;
  display: block;
  width: 100%;
  color: #bdbdbd;
  font-size: 1rem;
}
.signup-page-link {
  /* border: 1px red solid; */
  display: block;
  width: 100%;
  max-width: max-content;
  text-decoration: none;
  color: #cf0000;
  font-weight: 500;
  text-transform: capitalize;
  font-size: 1.1rem;
}
.signup-page-link:hover {
  color: #cf0000;
}

.logButt {
  color: #fff !important;
  background-color: #cf0000 !important;
}
.formLog form.label {
  margin-top: 6px;
  margin-bottom: 0px;
}

/* .signup:hover{
 color:#000;
} */
@media (max-width: 768px) {
  .logImg {
    display: none;
  }
  .loginForm {
    display: flex;
    justify-content: center;
    margin-top: 13px;
  }

  .mobileBtn {
    width: 100%;
    margin-top: 5px;
  }
}

.form-control {
  border: 1px #cf0000 solid !important;
  background-color: #fff;
}
.form-control input:selected {
  background-color: #fff;
}

.errmsg {
  color: #cf0000;
}

.persistCheck {
  font-size: 0.75rem;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-basis: 50%;
}

.persistCheck label {
  margin: 0;
  font-size: 0.8rem;
}

.forgotP {
  color: #1a1a1a;
  text-decoration: none;
  margin-top: 10px;
  margin-bottom: 10px;
  flex-basis: 50%;
  font-size: 0.8rem;
  text-align: right;
}

.forgotP:hover {
  color: #cf0000;
}

.password-visibility {
  position: absolute;
  top: 8px;
  right: 8px;
  color: #1b1b28;
}

.login-addon {
  position: absolute;
  top: 8px;
  left: 8px;
  color: #1b1b28;
}
