.wrapper{
  /* border: 1px red solid; */
  width: 100%;
  /* max-width: 20rem; */
  height: 100%;
  background-color: #fff;
  flex: 1;
  border-radius: 0.4rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.container{
  /* border: 1px red solid; */
  width: 100%;
}
.accountIcon{
  /* border: 1px red solid; */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1.5rem;
}
.icon{
  /* border: 1px red solid; */
  width: 100%;
  max-width: 3.5rem;
  height: 3.5rem;
  border-radius: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: lightgrey;
  user-select: none;
  cursor: pointer;
}
.miniTitle{
  width: 100%;
  font-size: 1.3rem;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
}
.profileBox{
  /* border: 1px red solid; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 1rem;
  /* grid-template-columns: 1fr 1fr 1fr; */
}
.profileOptionsBox{
  /* border: 1px red solid; */
  width: 100%;
  max-width: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #333333;
  text-decoration: none;
}
.profileOptionsBox:hover{
  color: #333333;
}
.circle{
/* border: 1px red solid; */
font-size: 1.3rem;
font-weight: 600;
color: #cf0000;
}
.profileTitle{
  font-size: 0.9rem;
  text-transform: capitalize;
  font-weight: 600;
  text-align: center;
}
