.navP{
    padding: 0 4% !important;
    background-color: #cf0000;
    display: flex;
    justify-content: space-between;
    height: 5vh;
  
}
.navP a{
    color: #fff !important;
}
.navP .dropLink{
    color:#cf0000 !important;
    background-color: #fff;
}
.navbar-toggler span{
    color: #fff
}
@media (min-width: 992px){
    .navbar-expand-lg .navbar-collapse {
    justify-content: flex-end !important;
}
}
.navP Button{
    margin-left: auto;
}
.logoHide img{
    max-width: 15%
}
.logoHideDiv{
    display: flex
}
@media  (min-width: 991px){
    .logoHide{
        display: none;
    }
}

.show>.dropdown-menu {
    display: block;
    position: absolute;
  }
@media (min-width: 991px){
    .whitelogo{
    display: none
    }
}

.logOut{
    color: #fff;
    cursor: pointer;
    margin-left: 0.8rem;
}