.wrapper {
  /* border: 1px white solid; */
  width: 100%;
  max-width: 75%;
  margin: 0 auto;
  /* background-color: #E2231A; */
  /* padding: 0 1.5rem; */
  color: #fff;
  /* padding-top: 0.5rem; */
}
.container {
  /* border: 1px white solid; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.navInfo {
  /* border: 1px white solid; */
  width: 100%;
  max-width: max-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
}
.navInfo .option {
  max-width: max-content;
  color: #fff;
  text-decoration: none;
  font-size: 0.85rem;
  text-transform: capitalize;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  cursor: pointer;
}
.option .underline, .helpBox .underline {
  border: 1px #fff solid;
  width: 100%;
  background-color: #fff;
  transform: translateX(-110%);
  transition: all 0.5s ease-in-out;
}
.option .active {
  transform: translateX(0);
}
.option:hover .underline, .helpBox:hover .underline, .active .underline {
  transform: translateX(0);
}
.socialOptions{
   max-width: max-content;
  font-size: 0.85rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  /* overflow: hidden; */
}
.socialIcon{
  font-size: 1.3rem;
  cursor: pointer;
}
.authBox {
  /* border: 1px white solid; */
  width: 100%;
  max-width: max-content;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
}
.helpBox {
  max-width: max-content;
  font-size: 0.85rem;
  text-transform: capitalize;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: #fff;
  overflow: hidden;
  cursor: pointer;
}
.helpBox:hover{
  color: #fff;
}
.authNameBox{
  /* border: 1px white solid; */
  width: 100%;
  max-width: max-content;
}
.authNameBox span{
  color: #fff;
  font-size: 0.85rem;
}
.logoutBtn{
  border: none;
  outline: none;
  background: transparent;
  margin-left: 0.5rem;
  color: #fff;
  font-size: 0.85rem;
}

@media screen and (max-width: 1450px) {
  .wrapper {
  max-width: 85%;
  }
}

/* @media screen and (max-width: 1100px) {
  .wrapper {
    padding: 1rem 2rem;
  }
}
@media screen and (max-width: 650px) {
  .wrapper {
    padding: 0.5rem 1rem;
  }
  .info {
    font-size: 0.7rem;
  }
} */
