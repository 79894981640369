.wrapper{
  width: 100%;
  max-width: max-content;
}
.authBox{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  cursor: pointer;
}
.text{
  width: 100%;
  max-width: max-content;
   color: #fff;
  text-decoration: none;
  display: block;
  font-size: 0.85rem;
  font-weight: 600;
}
.text:hover{
  text-decoration: underline;
  color: #EDEDED;
}