.wrapper{
  display: none;
}
@media screen and (max-width:650px) {
  .wrapper{
    display: block;
    width: 100%;
    padding: 1rem;
  }
  .logoConatiner{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
}