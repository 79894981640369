.wrapper{
  /* border: 1px red solid; */
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 0.3rem;
}
.container{
  /* border: 1px red solid; */
  width: 100%;
  max-width: 50%;
  margin: 0 auto;
  border-radius: 0.3rem;
  box-shadow: 0 0.3em 0.5em rgba(0, 0, 0, 0.3);
  padding: 1rem;
}
.withrawIdBox{
  /* border: 1px red solid; */
  width: 100%;
  background-color: #f2f2f2;
  padding: 1rem 0.5rem;
  border-radius: 0.4rem;
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}
.walletId{
  font-size: 1rem;
  font-weight: 600;
  color: #cf0000;
}
.withdrawalType{
  border: none;
  outline: none;
  width: 100%;
  max-width: max-content;
  padding: 0.1rem 0.5rem;
  color: #fff;
  background-color: #cf0000;
  border-radius: 0.6rem;
  font-size: 1rem;
}
.topupBox{
  /* border: 1px red solid; */
  width: 100%;
}
.topupTitle{
  /* border: 1px red solid; */
  width: 100%;
  text-align: center;
  margin-bottom: 0.6rem;
  font-weight: 600;
  font-size: 1rem;
}
.topupContent{
  /* border: 1px red solid; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.topupOption{
  /* border: 1px red solid; */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
.imgWrapper{
  /* border: 1px red solid; */
  width: 100%;
  max-width: 20rem;
  height: 10rem;
  cursor: pointer;
}
.img{
  width: 100%;
  height: 100%;
}
.topupName{
  width: 100%;
  text-align: center;
  font-weight: 600;
  cursor: pointer;
}
.noTopup{
  /* border: 1px red solid; */
  width: 100%;text-align: center;
  font-size: 1.1rem;
  font-weight: 600;
  padding: 1.5rem 0;
}
.modalContainer{
  /* border: 1px red solid; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
}
.modalTitle{
  /* border: 1px red solid; */
  width: 100%;
  height: 5%;
  text-align: center;
  margin-bottom: 1rem;
}
.modalWrapper{
  /* border: 1px red solid; */
  width: 95%;
  height: 100%;
}
.amountInputBox{
  /* border: 1px red solid; */
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}
.inputLabel{
  /* border: 1px red solid; */
  width: 100%;
  font-size: 1rem;
  margin-bottom: 0.2rem;
}
.inputNairaBox{
  border-bottom: 1px grey solid;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.currencyIcon{
  /* border: 1px red solid; */
  width: 100%;
  max-width: max-content;
  font-size: 1.5rem;
  font-weight: 600;
  color: green;
}
.amountInput{
  outline: none;
  border: none;
  outline: none;
  width: 100%;
  height: 2rem;
  background-color: transparent;
  font-size: 1.1rem;
}
.paymentInfoTitle{
  /* border: 1px red solid; */
  width: 100%;
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 0.6rem;
}
.emptyBankList{
   /* border: 1px red solid; */
  width: 100%;
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 4rem;
}
.acctWrap{
  /* border: 1px blue solid; */
  width: 100%;
  height: 17rem;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  overflow-y: auto;
}
.acctWrap::-webkit-scrollbar{
  display: none;
}
.acctBox{
  /* border: 1px red solid; */
  width: 100%;
}
.transBtnActive, .transBtnBlur{
  display: block;
  border: none;
  outline: none;
  width: 100%;
  font-size: 1.1rem;
  color: #fff;
  background-color: #cf0000;
  padding: 0.5rem 0;
  border-radius: 0.3rem;
  cursor: pointer;
}
.transBtnBlur{
  opacity: 0.5;
}
.paymentDrawerBox{
  /* border: 1px red solid; */
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.paymentPin{
  /* border: 1px red solid; */
  width: 100%;
  text-align: center;
  margin-bottom: 0.5rem;
}
.paymentAmountBox{
  /* border: 1px red solid; */
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
}
.paymentAmount{
  /* border: 1px red solid; */
  color: green;
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0 0.3rem ;
}
.errorBox{
  color: red;
  width: 100%;text-align: center;
}
.paymentInputContainer{
  /* border: 1px red solid; */
  width: 100%;
  max-width: 70%;
  margin: 0 auto;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.passwordInput{
  width: 100%;
  outline: none;
  border: 1px grey solid;
  font-size: 1.5rem;
  border-radius: 0.4rem;
  text-align: center;
}
.passwordInput::placeholder{
  font-size: 1.2rem;
}
.payNow{
  display: block;
  outline: none;
  border: none;
  width: 100%;
  max-width: 10rem;
  margin: 0 auto;
  background-color: #cf0000;
  padding: 0.4rem 0;
  color: #fff;
  border-radius: 0.3rem;
  margin-bottom: 0.5rem;
}
.passwordLink{
  display: block;
  color: #cf0000;
  text-decoration: none;
  width: 100%;
  max-width: max-content;
  font-weight: 600;
  margin: 0 auto;
}
.passwordLink:hover{
  text-decoration: underline;
}