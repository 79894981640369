.navW{
    padding: 0 4% !important;
    background-color: #ededed;
    border-bottom: solid #cf0000 1px;
}

.navTwo img{
    max-width: 60%
}

.navIcon {
    border-radius: 4px;
    color: #000000;
    padding: 10px;
    margin: auto 9px;
    border: 1px solid #000000;
    position: relative;
}
.navIcon:hover{
    color:#cf0000;
    border: 1px solid #cf0000;
}
.secondNavul{
    width: 76%;
    display: flex;
    justify-content: space-around;
}
@media (max-width: 1360px) and (min-width: 1109px){
    .secondNavul{
        width: 72%
    }
}
@media (max-width: 1108px) and (min-width: 991px){
    .secondNavul{
        width: 67%
    }
}
@media (max-width: 991px){
    .navW{
        display:none !important;
    }
}
.navbar-toggler-icon{
    background-image: url('https://spng.pinpng.com/pngs/s/163-1639439_menu-icon-white-menu-bar-icon-hd-png.png') !important;
}
@media (min-width: 992px){
.navW .navTwoSpread {
    justify-content: space-evenly !important;
}
}

.userBal p{
margin-bottom: 0px;
}

.baly{
    display: flex;
    align-items: center;
}

.hearder-cart-counter{
    position: absolute;
    border-radius: 40px;
    padding: 2px 6px;
    background-color: #cf0000;
    color: #ededed;
    top: -3px;
}