.tab-list {
    padding-left: 0;
    width:100%;
    
    
  }
  
  .tab-list-item {
    display: inline-block;
    list-style: none;
    margin-bottom: -1px;
    padding: 0.5rem 0.75rem;
    background-color: #f3f3f3;
    text-align:center;
    margin: 0 0.2rem;
    width:24%;
    cursor: pointer;
    
  }
  
  .tab-list-active {
    background-color: #E2231A;;
    border: solid #ccc;
    border-width: 1px 1px 0 1px;
    color:#fff;
  }
