.wrapper{
  /* border: 1px red solid; */
  width: 100%;
  min-height: 100%;
}
.container{
  /* border: 1px red solid; */
  width: 100%;
  margin: 2rem 0;
}
.title{
  /* border: 1px red solid; */
  width: 100%;
  max-width: max-content;
  margin: 0 auto;
  font-weight: 400;
  margin-bottom: 1rem;
}
.formContainer{
  /* border: 1px red solid; */
  width: 100%;
  max-width: 30rem;
  margin: 0 auto;
  margin-bottom: 4rem;
  border-radius: 0.3rem;
  background-color: #fff;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}
.inputBox{
  width: 100%;
}
.registerButton{
  outline: none;
  border: none;
  background-color: #cf0000;
  width: 100%;
  font-size: 1.2rem;
  padding: 0.5rem 0;
  border-radius: 0.3rem;
  color: #fff;
  margin-top: 1rem;
}
.terms, .cookies{
  /* border: 1px red solid; */
  width: 100%;
  max-width: 55%;
  margin: 0 auto;
  text-align: center;
}
.terms{
  margin-bottom: 1rem;
}
.codeWrapper{
  width: 100%;
}
.codeBox{
  /* border: 1px red solid; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.code{
  cursor: pointer;
}
.codeinput{
  width: 70%;
}