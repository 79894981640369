.content{
    min-height: 60vh;
}

.cart-header{
    margin: 1.5rem 0.2rem;
    padding: 0.1rem 15% ;
    
}

.cart-total{
    font-weight: bolder;
    font-size: 18px;
    padding: 1rem 0;
}

.column-name{
    padding: 1rem 0;
}


.column-name >div{
    font-weight: bolder;
}

.cart-body{
    margin: 1rem 15% 1rem;
    padding: 0.5rem 1rem;
}

.check-out-wrap{
    /* border: 1px red solid; */
    margin: 1rem 15% 1rem;
    padding: 0.5rem 1rem;
    box-shadow: 0 0.3em 0.5em rgba(0, 0, 0, 0.3);
    position: sticky;
    bottom: 0;
}

.marchant-name-wrap{
    padding: 0.7rem 0; 
}

.marchant-name-wrap > .marchant-name{
    margin-left: 1rem;

}

.marchant-name-wrap > .marchant-name a{
    font-size: 16px;
    color: #2a2a2a;
    text-decoration: none;
}

.select-one-item{
    flex-basis: 2%;
}

.cart-item-image{
    flex-basis: 6%;
    height: 81px;
}

.cart-item-image  img{
    height: 79px;
    width: 79px;
}

.info-props-wrap{
    flex-basis: 80%;
    padding: 0.5em 0;
}

.item-total{
    flex-basis: 9%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #cf0000;
    font-weight: bolder;
    
}

.item-title{
    margin-bottom: 1.2em;
    padding-left: 1rem;
}

.item-title  a:hover{
    color: #cf0000 !important;
}

.spec-select{
    flex-basis: 3%;
}

.spec-img{
    flex-basis: 3%;
    height: 31px;
}

.spec-img img{
    width: 29px;
    height: 29px;
    border: 1px solid #ccc;
    border-radius: 2px;;
}

.spec-value{
    flex-basis: 43%;
    background-color: #f9f9f9;
    color: #2a2a2a;
    padding: 5px 2px;
    border-radius: 2px;
    text-align: left;
}

.spec-qty{
    flex-basis: 10%;
}

.spec-delete-btn{
    flex-basis: 5%;
    text-align: center;
    padding: 5px 2px;
    
}

.spec-trash:hover{
    cursor: pointer;
    color: #cf0000;
}

.spec-listed-price{
    flex-basis: 12%;
    text-align: center;
    padding: 5px 2px;
}

.spec-discount-price{
    flex-basis: 12%;
    text-align: center;
    padding: 5px 2px;
}

.spec-wrap{
    margin-bottom: 2em;
    text-align: center;
    padding-left: 1em;
    border:none;
}

.cart-qty-btn{
    flex-basis: 25%;
    height:2rem;
    background-color: #f0f0f0;
    border:0;
  }

  .cart-qty-input{
    width: 50%;
    height:2rem;
    text-align: center;
    border: 1px solid #f0f0f0;
  }

  .cart-qty-input:focus{
    outline: none;
  }

  .delete-selected-btn button{
    border: 0;
    padding: 0.5rem 0.5rem;
    border-radius: 8px;
  }

  .delete-selected-btn button:hover{
    opacity: 0.7;
  }

  .check-out-inner-left{
    padding:0.7rem 0;
    flex-basis:20%;
  }

    .select-all-bottom{
        /* border: 1px red solid; */
        /* margin-top: 0.6rem; */
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.3rem;
    }

    .check-out-inner-right{
        padding:0.7rem 0;
        flex-basis:80%;
      }

      .check-out-inner-right >div{
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
      }

      .cart-calculate button{
        border: 0;
        padding: 0.5rem 0.5rem;
        border-radius: 8px;
        background-color: #cf0000;
        color: #f0f0f0;
        font-weight: bolder;
        font-size: 16px;;
      }

      .cart-calculate button:hover{
        background-color: #ff4646;
      }

      .cart-bottom-total{
        color:#cf0000;
        font-weight: bold;
        font-size: 18px;
      }



    .stiky-bottom{
        position: fixed;
         z-index: 1;
        bottom: -1em;
        width: 70%;
    }
        
   .spec-qty span{
      color:#cf0000;
        font-size: 10px;
       display:none;
 }

 .modal-w{
    width: 20rem !important;
 }

 .empty-cart-wrap{
    /* border: 1px red solid; */
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 40vh;
    background-color: #ffffff;
}
.empty-cart-info{
    /* border: 1px red solid; */
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}
.empty-cart-info span{
    font-size: 1.5rem;
    color:#333333;
    text-align: center;
}
.empty-cart-info span a{
    color: #cf0000;
    font-size: 1.5rem;
    text-decoration: none;
}
.empty-cart-info span a:hover{
    color: #cf0000;
}

.select-all{
    /* border: 1px red solid; */
    display: flex;
    align-items: center;
}
/* .empty-cart-wrap img{
    height: 20rem;
    width: 30rem;
} */


/* .empty-cart-wrap div{
    color:#a6a6a6;
    padding-left: 3rem;
} */