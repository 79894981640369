.wrapper{
  /* border: 1px red solid; */
  width: 100%;
  min-height: 80vh;
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.container{
  /* border: 1px red solid; */
  width: 100%;
  max-width: 30rem;
  margin: 0 auto;
  padding: 1rem;
  border-radius: 0.3rem;
  background-color: #fff;
}
.refHistoryTitle{
  /* border: 1px red solid; */
  width: 100%;
  text-align: center;
  margin-bottom: 1.5rem;
}
.refTable{
  /* border: 1px red solid; */
  width: 100%;
  border-collapse: collapse;
}
.refTable thead{
  /* border: 1px red solid; */
  width: 100%;
}
.refTable tr:hover{
  background-color: #ddd;
}
.refTable tr{
  width: 100%;
}
.refTable thead th{
  /* border: 1px red solid; */
  width: 20%;
   text-transform: capitalize;
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
  padding: 1rem 0.5rem;
}
.refTable thead .sn{
  width: 5%;
}
.refTable thead .email{
  width: 50%;
}
.refTable tbody{
/* border: 1px red solid; */
width: 100%;
}
.refTable tbody tr{
  width: 100%;
}
.refTable tbody tr td{
/* border: 1px red solid; */
text-align: center;
padding: 1rem 0.5rem;
font-size: 1.1rem;
}
.noReferee{
  font-size: 1.2rem;
  text-align: center;
}