.transactions-wrapper {
  /* border: 1px red solid; */
  width: 100%;
}
.transaction-search {
  /* border: 1px red solid; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.delete-feedback {
  /* border: 1px red solid; */
  width: 100%;
  max-width: max-content;
  margin: 0 auto;
  margin-bottom: 0.5em;
  color: red;
  font-size: 1rem;
}
.multiple-delete {
  border: none;
  outline: none;
  background-color: #ddd;
  width: 100%;
  max-width: max-content;
  margin: 0 auto;
  font-size: 1.1rem;
  padding: 0.5rem;
  border-radius: 0.4rem;
  margin-bottom: 0.5rem;
  background-color: crimson;
  color: white;
}


.transaction-table {
  width: 100%;
  border-collapse: collapse;
}
.head-checkbox {
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px red solid; */
}
.transaction-table thead,
.transaction-table tbody {
  /* border: 1px blue solid; */
  width: 100%;
}
.transaction-table thead {
  background-color: #f2f2f2;
}
.transaction-table tr {
  /* margin-bottom: 0.5rem; */
  width: 100%;
  /* text-align: center; */
}
.transaction-table tbody:nth-child(odd) {
  background-color: #f2f2f2;
}

.transaction-table tbody tr:hover {
  background-color: #ddd;
}
.transaction-table th {
  /* border: 1px red solid; */
  padding: 12px 0;
  color: #333333;
  font-weight: 600;
  text-align: center;
}
.transaction-table td {
  /* border: 1px red solid; */
  padding: 8px 0;
  text-align: center;
  /* vertical-align: center; */
}
.data-date {
  /* border: 1px red solid; */
  width: 10rem;
}
.status-pending {
  color: #e5d815;
}
.status-completed {
  color: #0e8a5c;
}
.status-rejected {
  color: crimson;
}
.data-trash {
  color: #59595a;
  cursor: pointer;
}

.data-trash:hover {
  color: crimson;
}
.trans-subject {
  width: 13rem;
  height: 1.6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
