.shippingcard h5{
    margin-bottom: 2%;
    font-weight: 600;
}

.shippingcard {
    margin: 0 1.5rem;
    padding: 1rem;
}
.addressBtn{
    display: flex;
    justify-content: space-evenly;
    margin-top: 7%;
}

.addressBtn a{
    width: 13%;
    justify-content: space-evenly;
    align-content: center;
    border: 1px solid #cf0000;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 4px 0px;  
    text-decoration: none;
    color: #cf0000    
}

.addressBtn a p{
    margin-bottom: 0px;
}
.shipCard{
    background-color: #CF000012;
    padding: 2%;
    border-radius: 5px;
    margin-bottom: 1rem;
}
.defAdd{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0px;
}
.iconsEdit:hover{
    color:#fff;
    background-color: #cf0000;
}
.addAddress button{
    width: 13%;
    justify-content: space-evenly;
    align-content: center;
    border: 1px solid #cf0000;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 4px 0px;
    text-decoration: none;
    color: #fff !important;
    background-color: #cf0000;

}
.addAddress{
    margin-top: 4%;
}
.addAddress p{
    margin-bottom: 0px;
}

.addAddress button:hover{
    color: #cf0000 !important;
    background-color: #fff;
}
@media (max-width:769px){
    .dView {
      display: none;
    }
  }

  .address-container {
    padding: 0.7rem 0;
 }

 .header-wrap{
    border-bottom: 1px solid #ccc;
    padding: 0.7rem 0;

 }

 .address-container .header-wrap > div{
    font-weight: 700;
    font-size: 17px;
    text-align: center;
 }

  .address-container .header-wrap .name-h{
     flex-basis: 14%;
  }

  .address-container .header-wrap .region-h{
    flex-basis: 16%;
 }

  .address-container .header-wrap .address-h{
    flex-basis: 30%;
 }

 .address-container .header-wrap .phone-h{
    flex-basis: 10%;
 }

 .address-container .header-wrap .postal-h{
    flex-basis: 10%;
 }

 .address-container .header-wrap .default-h{
    flex-basis: 10%;
 }

 .address-container .header-wrap .action-h{
    flex-basis: 10%;
 }

 .address-container .content-wrap .add-content:not(:last-child){
    border-bottom: 1px solid #ccc;
 }

 .address-container .content-wrap .add-content > div{
    text-align: center;
    padding: 1rem 0;
 }

 .address-container .content-wrap .add-content .name-c{
    flex-basis: 14%;
 }

 .address-container .content-wrap .add-content .region-c{
   flex-basis: 16%;
}

 .address-container .content-wrap .add-content .address-c{
   flex-basis: 30%;
}

.address-container .content-wrap .add-content .phone-c{
   flex-basis: 10%;
}

.address-container .content-wrap .add-content .postal-c{
   flex-basis: 10%;
}

.address-container .content-wrap .add-content .default-c{
   flex-basis: 10%;
}

.address-container .content-wrap .add-content .action-c{
   flex-basis: 10%;
}

.add-action button{
    padding: 0.3rem 0.5rem;
    border: 0;
    border-radius: 50%;
    text-align: center;
}

.add-action button:last-child{
    background-color: crimson;
    color: #fff;
    margin-left: 1rem;
}

.add-action button:first-child{
    background-color: #00e600;
    color: #fff;
    margin-right: 1rem;
}

.add-action button:first-child:hover{
    background-color: #00b300;
}

.add-action button:last-child:hover{
    background-color: #c51236;
}

.no-default{
    cursor: pointer;
    color: #007acc;
}