.wrapper{
  /* border: 1px red solid; */
  background-color: #CF0000;
  min-height: 85vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 2rem 0;
}
.logImgBox {
    /* border: 1px red solid; */
    width: 100%;
    max-width: 40%;
    height: 65%;
}
.logImgBox a{
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 0px;
}
.logImgBox img{
    width: 100%;
    height: 100%;
    border-radius: 0px;
}
.formBox{
  /* border: 1px red solid; */
  width: 100%;
  max-width: 30%;
  background-color: #fff;
  border-radius: 0.3rem;
  padding: 2rem;
}
.loginTitle{
   color: #CF0000;
    margin-bottom: 1rem;
}
.formContainer{
  /* border: 1px red solid; */
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.emailBox{
  /* border: 11px red solid; */
  width: 100%;
  display: flex;
  flex-direction: column;
}
.emailBox input{
  padding: 0.5rem;
  border: 1px solid #CF0000;
  border-radius: 0.3rem;
  font-size: 1rem;
}
.emailBox input::placeholder{
  font-weight: 500;
}
.phoneNumberBox{
  /* border: 11px red solid; */
  width: 100%;
}
.phoneBox{
  width: 100%;
}
.getCode{
  /* border: 1px red solid; */
  width: 100%;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-self: space-between;
  gap: 1rem;
  /* margin-bottom: 0; */
}
.getCodeInput{
  /* border: 11px red solid; */
  width: 85%;
}
.codebtn{
  /* border: 1px red solid; */
  width: 100%;
  max-width: max-content;
  color: #CF0000;
  font-weight: 600;
  cursor: pointer;
}
.passwordBox, .paymentPinBox{
  /* border: 11px red solid; */
  width: 100%;
}
.password{
  /* border: 1px red solid; */
  width: 100%;
  position: relative;
}
.password .visible{
  /* border: 1px red solid; */
  position: absolute;
  right: 0.5rem;
  top: 0.4rem;
}
.paymentPin{
  width: 100%;
}
.signupBtnWrapper{
  /* border: 1px red solid; */
  width: 100%;
  background: #CF0000;
  text-align: center;
  border-radius: 0.3rem;
  padding: 0.5rem 0;
}
.signupBtnWrapper button{
  display: block;
  width: 100%;
  background: transparent;
  border: none;
  outline: none;
  color: #fff;
  font-size: 1rem;
}
.signuplink{
  /* border: 1px red solid; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.2rem;
}
.signuplink span{
    width: 100%;
    max-width: max-content;
    display: block;
    width: 100%;
    color: #BDBDBD;
    font-size: 1rem;
}
.signupPageLink{
    display: block;
    width: 100%;
    max-width: max-content;
    text-decoration: none;
    color: #CF0000;
    font-weight: 500;
    text-transform: capitalize;
    font-size: 1.1rem;
}
.signupPageLink:hover{
  color: #CF0000;
}
.privacyBox{
  /* border: 1px red solid; */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}
.privacyLink{
  /* border: 1px red solid; */
  display: block;
  width: 100%;
  max-width: max-content;
  color: #CF0000;
}
.privacyLink:hover{
  color: #CF0000;
}