.wallVi{
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
}
.cardW{
    background: #fff;
    padding:4%;
}
.addWallet{
    display: flex;
    justify-content: flex-end;
}
.walletFigure h2{
    margin-bottom: 0px;
}
.wallVi a, .addWallet a{
    text-decoration: none;
    color: #1c1b1f
}
.wallVi aa:hover, .addWallet a:hover{
    text-decoration: none;
    color: #cf0000
}
.walletFigure p{
    color: #cf0000
}
.walletFigure h1, .wallVi h5{
    color: #1c1b1f
}
.walletFigure h1{
    margin-bottom: 0px;
    padding-bottom: 0px;
    font-weight: 700;
}
