.orderHead{
    padding: 2%;
    background-color: #fff;
    margin-bottom: 2%;
}
.orderHead h5{
    margin-bottom: 0px;
    font-weight: 600;
    padding-left: 0.7em;
}
.orderHead p{
    padding-left: 0.7em;
}

.orderHead input:focus{
  outline: none;
}

.urlinput{
    margin-left: auto;
    margin-right: auto;
}

.fieldy {
    display:flex;
    position:realtive;
    margin:1em !important;
    flex-direction:row;
  }
  
  .fieldy>input[type=text],
  .fieldy>button {
    display:block;
  }
  
  .fieldy>input[type=text] {
    flex:1;
    padding:0.6em;
    border: none;
    border-radius: 5px 0px 0px 5px;
    background-color: #ededed;
  }
  
  .fieldy>button {
    padding:0.6em 0.8em;
    background-color: #cf0000;
    color: #fff;
    border:none;
    border-radius: 0px 5px 5px 0px;
  }
  .fieldy>input[type=text]::after{
    border: none;
  }

  .order-container{
    padding-top: 20px;
}

.order-container .order-list{
    padding: 0.5rem 15px;
    background-color: #fff;
   
}

.order-list-body{
    margin-top: 0.7rem;
    padding: 0 6%;
}

.o-img img{
    height: 80px;
    width: 80px;
}
.o-img{
    margin-right: 5px;
}
.o-info .o-title{
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
}

.o-info .o-props{
    font-weight: bolder;
}

.order-select{
  margin-right: 5px;
}

.order-num-wrap{
   background-color: #EDEDED;
   padding: 5px 5px;

}

.order-list-header > div{
  text-align: center;
}

.empty-order-wrap{
  min-height: 40vh;
  background-color: #ffffff;
  padding-left: 25%;
  padding-top: 5%;
  padding-bottom: 5%;
}

.empty-order-wrap img{
  height: 20rem;
  width: 30rem;
}


.empty-order-wrap div{
  color:#a6a6a6;
  padding-left: 3rem;
}

.status-color{
  color: #cf0000;
}


.order-action button{
   border: 1px solid #999;
   padding: 0 0.7rem;
   background-color: #fff;
   margin-right: 1rem;
   border-radius: 3px;
}

.order-num-wrap .pay-btn{
  color: #cf0000;
  border: 1px solid #cf0000;
}

.order-num-wrap .pay-btn:hover{
  color: #fff;
  border: 1px solid #cf0000;
  background-color: #cf0000;
}

.order-num-wrap .order-trash{
  cursor: pointer;
}

.order-num-wrap .order-trash:hover{
  color: #cf0000;
}

.order-checkout-box{
  margin: 1rem 0.7rem 0;
  padding: 1rem;
  background-color: #ffffff;
  position: fixed;
  bottom: 0;
  width: 65%;
}

.order-checkout-box button{
  padding: 0.7rem 1.5rem;
  border: none;
  background-color: crimson;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
}

.order-checkout-box div:first-child{
  line-height: 2.5rem;
}


