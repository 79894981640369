.wrapper{
  /* border: 1px red solid; */
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.container{
  /* border: 1px red solid; */
  width: 100%;
  max-width: 70%;
  /* height: 40rem; */
  margin: 0 auto;
  border-radius: 0.3rem;
  background-color: #fff;
  padding-bottom: 2rem;
}
.heading{
  /* border: 1px red solid; */
  width: 100%;
  height: 15rem;
  background-color: #cf0000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 2.5rem;
}
.title{
  color: #fff;
  width: 100%;
  max-width: max-content;
  margin: 0 auto;
  margin-bottom: 0.5rem;
}
.intro{
  /* border: 1px red solid; */
  color: #fff;
  font-size: 1.2rem;
  margin-bottom: 0;
}
.detailBox{
  /* border: 1px red solid; */
  width: 100%;
  padding: 0 2rem;
}
.detailBox h3{
  /* border: 1px red solid; */
  width: 100%;
  max-width: max-content;
  text-transform: capitalize;
  margin-bottom: 1rem;
  /* font-size: 2.5rem; */
}
.detailBox p{
  /* border: 1px red solid; */
  width: 100%;
  margin-bottom: 1rem;
  font-size: 1.2rem;
  line-height: 2.5rem;
  text-align: justify;
}
.linkBox{
  /* border: 1px red solid; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
}
.link{
  width: 100%;
  max-width: max-content;
  color: #cf0000;
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 600;
}
.link:hover{
  color: #cf0000;
  text-decoration: underline;
}