.wrapper {
  /* border: 1px red solid; */
  width: 100%;
  min-height: 90vh;
}
.container {
  /* border: 1px red solid; */
  width: 100%;
  max-width: 75%;
  margin: 2rem auto;
  padding: 1rem 5rem;
  background-color: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.title,
.FormTitle {
  /* border: 1px red solid; */
  width: 100%;
  text-align: center;
  text-transform: capitalize;
  margin-bottom: 2rem;
}
.ourInfoBox{
  /* border: 1px red solid; */
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}